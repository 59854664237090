<template>
  <div id="reports">
    <Navbar current="Denúncias" />
    <Sidebar />
    <Container col="col-12">
      <Header :isSubHeader="false" :iconClass="'fas fa-bullhorn text-primary'" title="Denúncias"
        subtitle="Registro das denúncias" :totalCount="totalItemsRegistered">
      </Header>

      <div class="card">
        <SearchBar @onSearch="search" />
        <LoadingContainer v-if="loadingItems" />
        <div v-else>
          <EmptyState v-if="!items.length" :totalItemsRegistered="totalItemsRegistered">
            <template v-slot:noItemsRegistered>
              <span class="fa-3x fa-solid fa-bullhorn text-black-50 mb-2"></span>
              <p>Nenhuma denúncia</p>
            </template>
          </EmptyState>
          <div v-else class="table-responsive">
            <table class="table table-sm table-nowrap card-table table-hover cursor-pointer">
              <TableHead :columns="columns" v-on:sort="sort" />
              <tbody class="list">
                <router-link tag="tr" v-for="(item, index) in items" v-bind:key="'tr-' + index"
                  :to="`${$paths.reports.detail}/${item.id}`">
                  <td>{{ item?.appointment?.user?.name }}</td>
                  <td>{{ item.appointment?.psychologist?.name }}</td>
                  <td>{{ item.content }}</td>
                  <td class="text-end">
                    <time>{{
                      $moment(item.created_at).format("DD/MM/YYYY[ - ]HH:mm")
                    }}</time>
                  </td>
                  <td class="text-right table-menu">
                    <div class="dropdown">
                      <a href="#" class="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <router-link tag="a" :to="`${paths.users.detail}/${item?.appointment?.user_id}`"
                          class="dropdown-item">
                          Visualizar usuário
                        </router-link>
                        <hr />
                        <router-link tag="a" :to="`${paths.psychologists.detail}/${item?.appointment?.psychologist_id}`"
                          class="dropdown-item">
                          Visualizar psicólogo
                        </router-link>
                      </div>
                    </div>
                  </td>
                </router-link>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row" v-if="!loadingItems">
        <div class="col-12 text-end">
          <span class="text-muted">mostrando <b>{{ countItemsShowing }}</b> de
            <b>{{ countItemsTotal }}</b> resultado(s)</span>
        </div>
      </div>
      <div class="row justify-content-center" v-if="!loadingItems && moreItemsToLoad">
        <div class="col-2">
          <FormButton label="Carregar mais" :loading="loadingMoreItems" @onClick="loadMore" />
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import ReportsController from "./ReportsController";
export default ReportsController;
</script>
