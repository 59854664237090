<template>
  <div class="row">
    <div class="col-12">
      <EmptyState v-if="!item.schedule?.length" :msg="' '" :title="'Nenhuma consulta'" />
      <table class="table table-borderless table-hover table-sm" v-else>
        <TableHead :columns="columns" />
        <tbody>
          <router-link v-for="(schedule, index) in item.schedule" v-bind:key="'hr-' + index"
            :to="`${$paths.appointments.detail}/${schedule.id}`" tag="tr" role="button">
            <td>
              {{ schedule.user?.name }} ({{
                schedule.user?.nickname
              }})
            </td>
            <td>
              {{
                $moment(schedule.date).format(
                  "DD/MM/YYYY[ - ]HH:mm"
                )
              }}
            </td>
            <td>
              {{
                schedule.type == "imediate"
                ? "Imediata"
                : "Agendada"
              }}
            </td>
            <td>
              <span v-if="$appointmentsService.getAppointmentStatus(schedule.status)?.title === 'Concluída'"
                class="badge bg-success-soft">
                Concluída
              </span>
              <span v-if="$appointmentsService.getAppointmentStatus(schedule.status)?.title === 'Cancelada'"
                class="badge bg-danger-soft">
                Cancelada
              </span>
              <span v-if="$appointmentsService.getAppointmentStatus(schedule.status)?.title === 'Recusado'"
                class="badge bg-warning-soft">
                Recusado
              </span>
            </td>
            <td class="text-end">
              {{
                $appointmentsService.getAppointmentPaymentStatus(
                  schedule
                ).title
              }}
            </td>
            <td class="text-end">
              <router-link class="btn btn-sm btn-white lift" :to="`${$paths.appointments.detail}/${schedule.id}`"
                target="_blank">
                <span class="fe fe-arrow-up-right"></span>
              </router-link>
            </td>
          </router-link>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: "PsiDetailAppointments",
  props: {
    item: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      columns: [
        {
          name: 'Usuário',
          sortName: null,
          order: null
        },
        {
          name: 'Data',
          sortName: null,
          order: null
        },
        {
          name: 'Tipo',
          sortName: null,
          order: null
        },
        {
          name: 'Status',
          sortName: null,
          order: null
        },
        {
          name: 'Pagamento',
          sortName: null,
          order: null
        },
      ],
    }
  }
}
</script>