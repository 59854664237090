<template>
  <div>
    <Navbar :current="id ? 'Editar Post' : 'Adicionar Post'" :link="$paths.posts.list" :linkName="'Posts'" />
    <Sidebar />
    <Container>
      <Header :title="id ? 'Editar Post' : 'Adicionar Post'" />
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-md-6">
              <SelectInput :required="true" label="Categoria" :model="$v.form.category_id" :options="categories" />
            </div>
            <div class="col-12 col-md-6">
              <SelectInput :required="true" label="Tipo" :model="$v.form.type" :options="types" />
            </div>
            <div class="col-12" v-if="!this.id">
              <label class="text-muted mb-3 mt-3 form-label">Imagem <i class="text-danger">*</i></label>
              <ImageCropper :accept="'image/*'" :customFileInputWidth="100" :aspectRatio="16 / 9"
                v-on:cropEnd="cropEnd" />
              <p v-if="$v.form.image.$invalid" class="text-danger">* Selecione uma imagem para o post</p>
            </div>
            <div class="col-12 mb-3" v-else>
              <label class="text-muted mb-3 mt-3 form-label">Imagem <i class="text-danger">*</i></label>
              <div class="w-25 position-relative">
                <div style="right: -8px; bottom: -8px" class="position-absolute">
                  <button @click="editImageClicked()" class="btn btn-primary rounded-circle"><span
                      class="fas fa-pencil"></span></button>
                  <button v-if="form.image" @click="removeImage()" class="btn btn-secondary rounded-circle ms-2"><span
                      class="fas fa-trash-alt"></span></button>
                </div>
                <img v-if="form.image" :src="form.image.base64" alt="..." class="avatar-img rounded-4"
                  style="height:100%; width: 100%; object-fit: fill">
                <img v-else :src="`${$baseImageUrl}${existingImage}`" alt="..." class="avatar-img rounded-4"
                  style="height:100%; width: 100%; object-fit: fill">
              </div>
              <ImageCropper :accept="'image/*'" :customFileInputWidth="100" :aspectRatio="16 / 9" v-on:cropEnd="cropEnd"
                inputId="content-add-message-input" :hiddenInput="true" />
            </div>
            <div class="col-12 col-md-12">
              <TextInput :required="true" label="Título" :maxLength="TITLE_MAX_LENGTH" :model="$v.form.title"
                type="text" placeholder="Informe um título para o artigo"
                :errorMsg="'Informe um título com ao menos 10 caracteres'" />
            </div>
            <div class="col-12 col-md-12">
              <TextAreaInput :rows="20" :required="true" label="Conteúdo" :model="$v.form.text" type="text"
                :maxLength="CONTENT_MAX_LENGTH" placeholder="Informe o conteúdo">
                <template v-slot:content>
                  <div class="text-end">
                    <small class="text-muted" style="font-size: 0.7rem">{{ CONTENT_MAX_LENGTH -
                      form.text.length
                    }} caracteres restantes</small>
                  </div>
                </template>
              </TextAreaInput>
            </div>

          </div>
          <form>
            <div class="row">
              <div class="col-12 text-center">
                <span class="text-danger text-center">{{ errorMessage }}</span>
              </div>
            </div>
            <FormButton :label="'Salvar'" :loading="submitting" :disabled="$v.$invalid" @onClick="submit" />
          </form>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import PostAddController from "./PostAddController";
export default PostAddController;
</script>
<style scoped>

</style>
