<template>
  <div id="chats">
    <Navbar current="Psicólogos" />
    <Sidebar />
    <Container col="col-12">
      <Header :headerClass="'mb-0'" :headerBodyClass="'pb-0 border-0'" :isSubHeader="false" title="Psicólogos"
        subtitle="Registro dos psicólogos" :iconClass="'fas fa-user-md text-primary'" :totalCount="totalPsiCount">
        <template v-slot:button>
          <div class="form-check form-switch">
            <label class="form-label text-muted" for="premiumCheckbox">Somente premium

            </label>
            <input class="form-check-input" v-model="onlyPremium" type="checkbox" id="premiumCheckbox"
              @change="handlePremiumFilterChanged()" />
          </div>
        </template>
      </Header>

      <div class="row align-items-center">
        <div class="col">
          <ul class="nav nav-tabs mb-4">
            <li tag="li" class="nav-item" v-for="(tab, index) in tabs" v-bind:key="'tabp-' + index"
              v-on:click="selectedTab = tab.id">
              <router-link tag="a" :to="`${$paths.psychologists.list}/${tab.id}`" class="nav-link"
                :class="{ active: selectedTab == tab.id }">
                {{ tab.name }}
                <span class="badge bg-primary-soft" style="font-size: 0.8rem">{{
                  tab.totalCount || 0
                }}</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>

      <div class="tab-content" id="myTabContent2">
        <div class="tab-pane fade" id="psychologists-all" role="tabpanel" aria-labelledby="psychologists-all-tab"
          tabindex="0" :class="{ 'show active': selectedTab == 'todos' }">
          <PsychologistsList :propFilters="{}" v-on:totalItemsChanged="totalChanged($event, 'todos')" />
        </div>
        <div class="tab-pane fade" id="psychologists-approved" role="tabpanel"
          aria-labelledby="psychologists-approved-tab" tabindex="0"
          :class="{ 'show active': selectedTab == 'aprovados' }">
          <PsychologistsList v-on:totalItemsChanged="totalChanged($event, 'aprovados')"
            :propFilters="{ approved: '1' }" />
        </div>
        <div class="tab-pane fade" id="psychologists-repproved" role="tabpanel"
          aria-labelledby="psychologists-repproved-tab" tabindex="0"
          :class="{ 'show active': selectedTab == 'reprovados' }">
          <PsychologistsList v-on:totalItemsChanged="totalChanged($event, 'reprovados')"
            :propFilters="{ approved: '0' }" />
        </div>
        <div class="tab-pane fade" id="psychologists-online" role="tabpanel" aria-labelledby="psychologists-online-tab"
          tabindex="0" :class="{ 'show active': selectedTab == 'online' }">
          <PsychologistsList v-on:totalItemsChanged="totalChanged($event, 'online')" :propFilters="{ online: '1' }" />
        </div>
        <div class="tab-pane fade" id="psychologists-imediate" role="tabpanel"
          aria-labelledby="psychologists-imediate-tab" tabindex="0" :class="{ 'show active': selectedTab == 'imediata' }">
          <PsychologistsList v-on:totalItemsChanged="totalChanged($event, 'imediata')" :propFilters="{ imediate: '1' }" />
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import PsychologistsController from "./PsychologistsController";
export default PsychologistsController;
</script>
