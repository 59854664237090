import ListMixinController from '../../mixins/ListMixinController'
export default {
  name: 'Users',
  mounted() {
    this.loadingItems = true
  },
  data() {
    return {
      approveId: null,
      activeId: null,
      loadingApprove: false,
      loadingActive: false,
      items: [
        {
          name: 'Termos de uso - Usuários',
          path: this.$paths.contracts.userTerms
        },
        {
          name: 'Termos de uso - Psicólogos',
          path: this.$paths.contracts.psiTerms
        },
        {
          name: 'Politica de privacidade',
          path: this.$paths.contracts.privacyPolicy
        }
      ],
      columns: [
        {
          name: 'Tipo',
          sortName: null,
          order: null
        },
      ],
    };
  },
  methods: {

  }
};