<template>
  <div class="sticky-top">
    <div class="card">
      <div class="card-img-top bg-primary-soft" style="height: 8rem"></div>
      <div class="card-body text-center">
        <div class="avatar avatar-xxl card-avatar card-avatar-top">
          <img v-if="item.profile_image && item.profile_image != '1'" :src="`${$baseImageUrl}${item.profile_image}`"
            alt="..." class="avatar-img border border-5 border-card bg-white shadow" style="border-radius:1rem;" />
          <img v-else src="@/assets/img/avatar_placeholder.svg" alt="..."
            class="avatar-img border border-5 border-card bg-white shadow" style="border-radius:1rem;" />
        </div>
        <h1 class="card-title fw-bold mb-3">
          {{ item.name }}
          <div v-if="item.subscription?.status == 'active'  || item.subscription?.status == 'trialing'" class="avatar" style="height: 1.4rem; width: 1.4rem;">
            <img src="@/assets/img/psiapp-plus-icon.png" alt="..." class="avatar-img rounded-circle mb-2">
          </div>
          
        </h1>
        <p><a :href="`${$appEnv == 'prod' ? 'https://app.psiapp.com.br/psi' : 'https://app-dev.psiapp.com.br/psi' }/${item.slug}`" target="_blank"> Link para perfil do psi <i class="fa-solid fa-arrow-up-right-from-square"></i></a></p>
        <div class="row">
          <div class="col">
            <div class="card">
              <div class="card-body text-start">
                <p class="text-muted mb-2">
                  Agendadas
                </p>
                <span class="h2 fw-bold mb-0">
                  <i class="fa-regular fa-calendar"></i>
                  {{ item.scheduled_as_psychologist_count }}
                </span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card">
              <div class="card-body text-start">
                <p class="text-muted mb-2">
                  Imediatas
                </p>
                <span class="h2 fw-bold mb-0">
                  <i class="fa-solid fa-bell-concierge"></i>
                  {{ item.imediate_as_psychologist_count }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="list-group list-group-flush my-n3">
          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col text-start">
                <p class="text-muted mb-0">
                  Avaliações
                </p>
              </div>
              <div class="col-auto">
                <p class="mb-0">
                  <span v-if="item.avg_rating">
                    <i class="fa-solid fa-star text-warning me-2"></i>
                    <span class="fw-bold">{{ item.avg_rating }}</span>
                  </span>
                  <span v-else>
                    <span class="">Sem avaliações</span>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="list-group-item" v-if="item.total_views">
            <div class="row align-items-center">
              <div class="col text-start">
                <p class="text-muted mb-0">
                  Views totais
                </p>
              </div>
              <div class="col-auto">
                <p class="mb-0">
                  {{ item.total_views }}
                </p>
              </div>
            </div>
          </div>
          <div class="list-group-item" v-if="item.total_views_this_month">
            <div class="row align-items-center">
              <div class="col text-start">
                <p class="text-muted mb-0">
                  Views esse mês
                </p>
              </div>
              <div class="col-auto">
                <p class="mb-0">
                  {{ item.total_views_this_month }}
                </p>
              </div>
            </div>
          </div>
          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col text-start">
                <p class="text-muted mb-0">
                  Psiapp+
                </p>
              </div>
              <div class="col-auto">
                <p class="mb-0">
                  <span v-if="item.subscription">
                    <i class="fa-solid fa-circle-check text-success me-2"></i>
                    <span class="fw-bold">Assinante</span>
                  </span>
                  <span v-else>
                    <i class="fa-regular fa-clock me-2"></i>
                    <span class="">Não assinante</span>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col text-start">
                <p class="text-muted mb-0">
                  Valor
                </p>
              </div>
              <div class="col-auto">
                <p class="mb-0">
                  <span>
                    {{ item.formatted_price }}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col text-start">
                <p class="text-muted mb-0">
                  Imediata
                </p>
              </div>
              <div class="col-auto">
                <p class="mb-0" v-if="item.imediate">
                  <i class="fa-solid fa-circle fa-xs text-success me-2"></i>
                  <span class="fw-bold">Disponível</span>
                </p>
                <p class="mb-0" v-else>
                  <i class="fa-solid fa-circle fa-xs text-muted me-2"></i>
                  <span>Indisponível</span>
                </p>
              </div>
            </div>
          </div>
          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col text-start">
                <p class="text-muted mb-0">
                  Online
                </p>
              </div>
              <div class="col-auto">
                <p class="text-success fw-bold mb-0" v-if="item.online">
                  <i class="fa-solid fa-circle fa-xs text-success me-2"></i>
                  <span class="fw-bold">Disponível</span>
                </p>
                <p class="mb-0" v-else>
                  <i class="fa-solid fa-circle fa-xs text-muted me-2"></i>
                  <span>Indisponível</span>
                </p>
              </div>
            </div>
          </div>
          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col text-start">
                <p class="text-muted mb-0">
                  Agenda
                </p>
              </div>
              <div class="col-auto">
                <p class="text-success fw-bold mb-0" v-if="!item.block_agenda">
                  <i class="fa-solid fa-circle fa-xs text-success me-2"></i>
                  <span class="fw-bold">Disponível</span>
                </p>
                <p class="mb-0" v-else>
                  <i class="fa-solid fa-circle fa-xs text-muted me-2"></i>
                  <span>Indisponível</span>
                </p>
              </div>
            </div>
          </div>
          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col text-start">
                <p class="text-muted mb-0">
                  Parcerias
                </p>
              </div>
              <div class="col-auto">
                <p class="text-success fw-bold mb-0" v-if="item.partnerships">
                  <i class="fa-solid fa-circle fa-xs text-success me-2"></i>
                  <span class="fw-bold">Ativo</span>
                </p>
                <p class="mb-0" v-else>
                  <i class="fa-solid fa-circle fa-xs text-muted me-2"></i>
                  <span>Inativo</span>
                </p>
              </div>
            </div>
          </div>
          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col text-start">
                <p class="text-muted mb-0">
                  Provedor da chamada
                </p>
              </div>
              <div class="col-auto">
                <p class="mb-0">
                  <span>{{ item?.callProviderLabel }}</span>
                </p>
              </div>
            </div>
          </div>

          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col d-grid">
                <a :href="'https://api.whatsapp.com/send?phone=55' +
                  $helper.cleanValue(item.phone)
                  " target="_blank" type="button" class="btn btn-success mb-2 block">
                  <i class="fa-solid fa-comment me-1"></i>
                  Enviar mensagem

                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PsiDetailSummary",
  props: {
    item: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      columns: [
        {
          name: 'Data realizada',
          sortName: null,
          order: null
        },
        {
          name: 'Qtd. de sessões',
          sortName: null,
          order: null
        },
        {
          name: 'Valor',
          sortName: null,
          order: null
        },
        {
          name: 'Status',
          sortName: null,
          order: null
        }
      ]
    }
  }
}
</script>
