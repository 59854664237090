<template>
  <nav class="navbar navbar-vertical fixed-start navbar-expand-md navbar-dark" id="sidebar">
    <div class="container-fluid">
      <!-- Toggler -->
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse"
        aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <router-link tag="a" :to="$paths.home" class="navbar-brand text-start">
        <img class="navbar-brand-img" src="@/assets/img/logo_dark.png" alt="Psiapp Backoffice" />
        <!-- <span style="font-size: 0.7rem" class="text-muted ms-3"></span> -->
      </router-link>

      <!-- User (xs) -->
      <div class="navbar-user d-md-none">
        <!-- Dropdown -->
        <div class="dropdown">
          <!-- Toggle -->
          <a href="#" id="sidebarIcon" class="dropdown-toggle" role="button" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <div class="avatar avatar-sm">
              <i class="fas fa-user-circle display-4 text-light"></i>
              <!-- <img src="./assets/img/avatars/profiles/avatar-1.jpg" class="avatar-img rounded-circle" alt="..."> -->
            </div>
          </a>

          <!-- Menu -->
          <div class="dropdown-menu dropdown-menu-end" aria-labelledby="sidebarIcon">
            <a href="#" class="dropdown-item">Profile</a>
            <hr class="dropdown-divider" />
            <a href="#" class="dropdown-item">Logout</a>
          </div>
        </div>
      </div>

      <!-- Collapse -->
      <div class="collapse navbar-collapse" id="sidebarCollapse">
        <!-- Navigation -->
        <ul class="navbar-nav">
          <router-link active-class="router-link-active" :to="$paths.home" tag="li" class="nav-item">
            <a class="nav-link">
              <i class="sidebar-icon fa-solid fa-chart-simple"></i>
              Visão geral
              <span class="badge bg-light text-dark fw-bold ms-auto">{{ $versionString }} {{ $envString }}</span>
            </a>
          </router-link>
        </ul>
        <hr class="navbar-divider" />
        <h6 class="navbar-heading mb-0">Sessões</h6>
        <ul class="navbar-nav">
          <router-link active-class="router-link-active" :to="$paths.appointments.list" tag="li" class="nav-item">
            <a class="nav-link">
              <i class="sidebar-icon far fa-calendar-check"></i>
              Sessões
            </a>
          </router-link>
          <router-link active-class="router-link-active" :to="$paths.ratings.list" tag="li" class="nav-item">
            <a class="nav-link">
              <i class="sidebar-icon fa-solid fa-star"></i>
              Avaliações
            </a>
          </router-link>
          <router-link active-class="router-link-active" :to="$paths.ratings.reported" tag="li" class="nav-item">
            <a class="nav-link">
              <i class="sidebar-icon fa-solid fa-exclamation-triangle"></i>
              Avaliações Reportadas
            </a>
          </router-link>
        </ul>
        <hr class="navbar-divider" />
        <template v-if="isAdmin">
          <h6 class="navbar-heading mb-0">Usuários</h6>
          <ul class="navbar-nav">
            <router-link active-class="router-link-active" :to="$paths.psychologists.list" tag="li" class="nav-item">
              <a class="nav-link">
                <i class="sidebar-icon fa-solid fa-user-md"></i>
                Psicólogos
              </a>
            </router-link>
            <router-link active-class="router-link-active" :to="$paths.users.list" tag="li" class="nav-item">
              <a class="nav-link">
                <i class="sidebar-icon fa-solid fa-user-friends"></i>
                Pacientes
              </a>
            </router-link>
            <router-link active-class="router-link-active" :to="$paths.admins.list" tag="li" class="nav-item">
              <a class="nav-link">
                <i class="sidebar-icon fa-solid fa-user-cog"></i>
                Administradores
              </a>
            </router-link>
          </ul>
          <hr class="navbar-divider" />
        </template>
        <h6 class="navbar-heading mb-0">Empresas</h6>
        <ul class="navbar-nav">
          <router-link active-class="router-link-active" :to="$paths.coupons.list" tag="li" class="nav-item">
            <a class="nav-link">
              <i class="sidebar-icon fa-solid fa-tags"></i>
              Códigos personalizados
            </a>
          </router-link>
          <router-link active-class="router-link-active" :to="$paths.partners.list" tag="li" class="nav-item">
            <a class="nav-link">
              <i class="sidebar-icon fa-solid fa-handshake"></i>
              Empresas parceiras
            </a>
          </router-link>
        </ul>
        <hr class="navbar-divider" />
        <h6 class="navbar-heading mb-0">Administração</h6>
        <ul class="navbar-nav">
          <router-link active-class="router-link-active" :to="$paths.videos.list" tag="li" class="nav-item">
            <a class="nav-link">
              <i class="sidebar-icon fa-solid fa-film"></i>
              Videos da home
            </a>
          </router-link>
          <router-link active-class="router-link-active" :to="$paths.contracts.list" tag="li" class="nav-item">
            <a class="nav-link">
              <i class="sidebar-icon fa-solid fa-file-contract"></i>
              Contratos
            </a>
          </router-link>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  computed: {
    userSession() {
      return this.$store.state.userSession;
    },
    chatBadge() {
      const userInfo = this.$store.state.userInfo;
      return userInfo?.chatBadge || userInfo?.appointmentBadge;
    },
  },
  mounted() {
    this.isAdmin = this.userSession?.type == "admin";
  },
  data() {
    return {
      isAdmin: false,
    };
  },
};
</script>

<style scoped>
.sidebar-icon {
  width: 25px;
  text-align: left;
}
</style>
