<template>

  <div class="d-flex align-items-center bg-dark text-light border-top border-top-2 border-primary vh-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0">
          <div class="text-center">
            <img src="@/assets/img/illustration_login.png" alt="..." class="img-fluid">
          </div>
        </div>
        <div class="col-12 col-md-5 col-xl-4 order-md-1 my-5">
          <div class="text-center mb-5">
            <img src="@/assets/img/logo_dark.png" alt="..." class="" height="80">
            <div class="text-center mt-3">
              <small class="text-white font-weight-bold">
                {{ $envString }}
              </small>
            </div>
          </div>
          <form>
            <TextInput label="Email" :model="$v.form.email" type="email" class="text-white"
              placeholder="nome@email.com" />
            <PasswordInput label="Senha" :model="$v.form.password" class="text-white" placeholder="Informe sua senha" />
            <div class="text-danger text-center">
              <small>{{ errorMessage }}</small>
            </div>
            <FormButton label="Entrar" :loading="submitting" :disabled="$v.$invalid"
              class="btn btn-lg w-100 btn-primary mb-3" @onClick="login" />
            <div class="text-center">
              <small class="text-muted text-center">
                Não é administrador do Psiapp? <a href="https://psiapp.com.br/">Voltar para home</a>.
              </small>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoginController from "./LoginController";
export default LoginController;
</script>
