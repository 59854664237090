<template>
  <div v-if="item.bank_account?.length">
    <div class="row">
      <div class="col-12">
        <TextInput :readOnly="true" label="Tipo da conta" :value="item.business_type == 'individual'
          ? 'Pessoa Física'
          : 'Jurídica'
          " />
      </div>
      <template v-if="item.business_type == 'company'">
        <div class="col-12 col-xl-6">
          <TextInput :readOnly="true" label="Nome da empresa" :value="`${item.company_name}`" />
        </div>
        <div class="col-12 col-xl-6">
          <TextInput :readOnly="true" label="CNPJ" :value="`${item.company_document_number}`" />
        </div>
      </template>
      <div class="col-12">
        <TextInput :readOnly="true" label="Titular da conta" :value="`${item.bank_account[0].legal_name}`" />
      </div>
      <div class="col-12 col-xl-6">
        <TextInput :readOnly="true" label="Banco" :value="`${item.bank_account[0].bank_code}`" />
      </div>
      <div class="col-12 col-xl-6">
        <TextInput :readOnly="true" label="Agência" :value="`${item.bank_account[0].agencia} ${item.bank_account[0].agencia_dv || ''
          }`" />
      </div>
      <div class="col-12 col-xl-6">
        <TextInput :readOnly="true" label="Conta" :value="`${item.bank_account[0].conta} ${item.bank_account[0].conta_dv || ''
          }`" />
      </div>
      <div class="col-12 col-xl-6">
        <TextInput :readOnly="true" label="CPF" :value="`${item.bank_account[0].document_number}`" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PsiDetailBankInfo",
  props: {
    item: {
      type: Object,
      default: () => { },
    },
  },
}
</script>