<template>
  <span>
    <span class="me-2 fw-bold">{{ rating }}.0</span>
    <span v-for="(_, index) in Array(maxRating).fill('')" v-bind:key="'rating-' + index">
      <span v-if="index + 1 <= rating" class="fas fa-xl fa-star text-warning"></span>
      <span v-else class="fas fa-xl fa-star text-light"></span>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    maxRating: {
      type: Number,
      default: 5,
    },
    rating: {}
  },
};
</script>

<style>

</style>