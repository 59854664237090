import ListMixinController from "@/mixins/ListMixinController";

export default {
  mixins: [ListMixinController],
  mounted() {
    this.loadingItems = true;
    this.getData();
  },
  data() {
    return {
      columns: [
        {
          name: "Nome",
          sortName: null,
          order: null,
        },
        {
          name: "Email",
          sortName: null,
          order: null,
        },
        {
          name: "Data de criação",
          sortName: null,
          order: null,
        },
      ],
    };
  },
  methods: {
    fetchPromise() {
      return this.$userService.list({
        ...this.filters,
        type: "admin",
        orderBy: this.columns.filter(
          (r) => r.order != null && r.sortName != null
        ),
      });
    },
    handleDetail(id) {
      this.$router.push({ path: `${this.$paths.admins.detail}/${id}` });
    },
  },
};
