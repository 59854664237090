import { email, required, sameAs } from 'vuelidate/lib/validators';
export default {
  name: 'AddAdmins',
  mounted() {
    this.loadingItems = true
    this.getData()
  },
  data() {
    return {
      item: {},
      errorMessage: '',
      submitting: false,
      id: this.$route.params.id,
      form: {
        name: ''
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        // email,
      },
      email: {
        required,
        email,
      },
      document_number: {
        required,
        // email,
      },
      phone: {
        required,
        // email,
      },
      country_code: {
        required,
        // email,
      },
      nickname: {
        required,
        // email,
      },
    }
  },
  methods: {
    async getData() {
      try {
        const res = await this.$userService.show(this.id)
        let data = res.data
        this.$set(this, 'form', { name: data.name, document_number: data.document_number, phone: data.phone, email: data.email, country_code: data.country_code, nickname: data.nickname })
        this.$set(this, 'item', data)
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingItems = false
      }
    },
    async submit() {
      try {
        this.submitting = true
        this.errorMessage = ''
        const values = this.form
        const res = await this.$userService.update(this.id, values)
        this.$eventBus.$emit('showToast', { title: 'Admin alterado', msg: `Admin alterado com sucesso` })
        this.$router.back()
      } catch (e) {
        this.errorMessage = e.message
      } finally {
        this.submitting = false
      }
    }

  }
};