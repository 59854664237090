<template>
  <nav class="navbar navbar-expand navbar-light d-none d-md-flex px-6" id="topbar">
    <div class="container-fluid">
      <nav aria-label="breadcrumb" class="me-auto ms-4">
        <ol class="breadcrumb fw-bold">
          <router-link v-if="link" tag="li" :to="link" class="breadcrumb-item d-flex align-items-center">
            <a>{{ linkName }}</a>
          </router-link>
          <router-link v-if="sublink" tag="li" :to="sublink" class="breadcrumb-item d-flex align-items-center">
            <a>{{ sublinkName }}</a>
          </router-link>
          <li class="breadcrumb-item active d-flex align-items-center" aria-current="page">
            {{ current }}
          </li>
        </ol>
      </nav>

      <div class="navbar-user">
        <div class="dropdown cursor-pointer" @mouseover="avatarOver" @mouseleave="avatarLeave">
          <span class="text-muted me-3 fw-bold">{{ userSession?.name }}</span>
          <a href="#" class="avatar avatar-sm dropdown-toggle">
            <img src="@/assets/img/avatar.svg" alt="..." class="avatar-img rounded-circle" />
          </a>
          <div class="dropdown-menu dropdown-menu-end">
            <router-link class="dropdown-item" tag="a" :to="`${$paths.admins.detail}/${userSession?.id}`">
              Minha conta
            </router-link>
            <hr class="dropdown-divider" />
            <span @click="logout" class="dropdown-item text-danger">Sair</span>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import $ from "jquery";
export default {
  name: "Navbar",
  props: {
    link: String,
    linkName: String,
    sublink: String,
    sublinkName: String,
    current: String,
  },
  computed: {
    userSession() {
      return this.$store.state.userSession;
    },
  },
  methods: {
    async logout() {
      try {
        await this.$userService.logout();
      } catch (error) {
        console.log("ERRO NO LOGOUT", error);
      } finally {
        this.$router.push(this.$paths.login);
      }
    },
    avatarOver() {
      $(".dropdown-menu").addClass("show");
      $(".dropdown-menu").attr("data-bs-popper", true);
    },
    avatarLeave() {
      $(".dropdown-menu").removeClass("show");
      $(".dropdown-menu").removeAttr("data-bs-popper");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.navbar {
  padding-left: 250px !important;
}
</style>
