export default {
  name: "ListMixinController",
  data() {
    return {
      totalItemsRegistered: 0,
      items: [],
      countItemsShowing: 0,
      countItemsTotal: 0,
      moreItemsToLoad: true,
      loadingMoreItems: false,
      loadingItems: false,
      // NOTE: Boolean pra saber se preciso setar o filtro de data
      hasDateFilter: false,
      filters: {
        page: 1,
        q: "",
      },
      columns: [],
    };
  },
  mounted() {
    if (this.hasDateFilter) {
      this.filters = {
        ...this.filters,
        date_start:
          this.$route.query.date_start ||
          this.$moment().startOf("month").format("YYYY-MM-DD"),
        date_end:
          this.$route.query.date_end ||
          this.$moment().endOf("month").format("YYYY-MM-DD"),
      };
    }
  },
  methods: {
    async getData(loadingMore = false, skipTotal = false) {
      try {
        if (!loadingMore) {
          this.$set(this.filters, "page", 1);
        }
        const res = await this.fetchPromise();
        let data = this.prepareResponse(res.data);
        if (!loadingMore) {
          this.$set(this, "items", data);
        } else {
          this.$set(this, "items", this.items.concat(data));
        }
        this.moreItemsToLoad = res.next_page_url != null;
        this.countItemsShowing = res.to || 0;
        this.countItemsTotal = res.total;

        if (this.filters.page == 1 && this.filters.q == "") {
          // so seta o o totalzao quando nao tem filtro
          if (!skipTotal) {
            this.$set(this, "totalItemsRegistered", res.total);
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingItems = false;
        this.loadingMoreItems = false;

        this.$set(
          this,
          "columns",
          this.columns.map((c) => {
            return { ...c, loading: false };
          })
        );
      }
    },
    /**
     * @return promise da lista que vc quer chamar
     * -fazer o override na tela pra chamar a funcao/service certo
     */
    async fetchPromise() {},
    /**
     * @return faz as mudanças na resposta e retorna pro getData
     * -fazer o override na tela pra tratar a resposta conforme necessario
     */
    prepareResponse(data) {
      return data;
    },
    loadMore() {
      this.loadingMoreItems = true;
      this.$set(this.filters, "page", this.filters.page + 1);
      this.getData(true);
    },
    search(string) {
      this.$set(this.filters, "q", string);
      this.getData();
    },
    sort(colIndex) {
      this.$set(this, "columns", this.$helper.sortCols(this.columns, colIndex));
      this.getData();
    },
  },
};
