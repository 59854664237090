<template>
  <div id="reported-ratings">
    <Navbar current="Avaliações reportadas" />
    <Sidebar />
    <Container col="col-12">
      <Header title="Avaliações reportadas" :isSubHeader="false" subtitle="Registro de avaliações reportadas pelos psis"
        :iconClass="'fa-solid fa-exclamation-triangle text-primary'" :totalCount="totalItemsRegistered">
      </Header>

      <div class="card">
        <SearchBar @onSearch="search" />
        <LoadingContainer v-if="loadingItems" />
        <div v-else>
          <EmptyState v-if="!items.length" :totalItemsRegistered="totalItemsRegistered">
            <template v-slot:noItemsRegistered>
              <span class="fa-3x fa-regular fa-star-half-stroke text-black-50 mb-2"></span>
              <p>Nenhuma avaliação encontrada</p>
            </template>
          </EmptyState>
          <div v-else class="table-responsive">
            <table ref="myTable" class="table table-sm table-nowrap card-table table-hover cursor-pointer">
              <TableHead :columns="columns" v-on:sort="sort" />
              <tbody class="list">
                <tr v-for="(item, index) in items" v-bind:key="'tr-' + index">

                  <td class="">
                    {{ item.id }}
                  </td>
                  <td class="">
                    <time class="">{{ $moment(item.created_at).format("DD/MM/YY") }}</time>
                  </td>
                  <td>
                    <template v-if="item.reason_report">
                      <span class="text-nowrap fw-bold" data-bs-toggle="tooltip" data-bs-placement="bottom"
                        :title="item.reason_report" aria-describedby="tooltip302047" :ref="`tooltip_reason_${index}`">
                        <i class="fa-solid fa-lg fa-exclamation-circle text-danger me-1"></i>
                        Sim
                      </span>
                    </template>
                    <template v-else>
                      <span class="text-muted">
                        Não houve
                      </span>
                    </template>
                  </td>
                  <td class="linkable" @click="goToAppointment(item.appointment?.id)">
                    {{ item.appointment?.id }}
                  </td>
                  <!-- <td>
                    <div class="avatar avatar-xs align-middle me-2">
                      <img src="@/assets/img/avatar_placeholder.svg" alt="..." class="avatar-img rounded-circle" />
                    </div>
                    <span>
                      ({{ item.user.id }}) <span class="fw-bold">{{ item.user.name }}</span>
                      <span v-if="item.user.deleted_at" class="text-danger fw-bold">
                        (Excluído)
                      </span>
                    </span>
                  </td> -->
                  <td>
                    <div class="avatar avatar-xs align-middle me-2">
                      <img v-if="item.psychologist?.profile_image"
                        :src="`${$baseImageUrl}${item.psychologist?.profile_image}`" alt="..."
                        class="avatar-img rounded-circle" />
                      <img v-if="!item.psychologist?.profile_image" src="@/assets/img/avatar_placeholder.svg" alt="..."
                        class="avatar-img rounded-circle" />
                    </div>
                    <span>
                      ({{ item.psychologist?.id }}) <span class="fw-bold">{{ item.psychologist?.name }}</span>
                      <span v-if="item.psychologist?.deleted_at" class="text-danger fw-bold">
                        (Excluído)
                      </span>
                    </span>
                  </td>
                  <td>
                    <template v-if="item.trouble && item.description">
                      <span class="text-nowrap fw-bold" data-bs-toggle="tooltip" data-bs-placement="bottom"
                        :title="item.description" aria-describedby="tooltip302047" :ref="`tooltip_trouble_${index}`">
                        <i class="fa-solid fa-lg fa-exclamation-circle text-danger me-1"></i>
                        Sim
                      </span>
                    </template>
                    <template v-else>
                      <span class="text-muted">
                        Não houve
                      </span>
                    </template>
                  </td>
                  <td>
                    <span v-if="item.call_completed || item.call_completed === null">
                      <i class="fa-solid fa-lg fa-check-circle text-success me-1"></i>
                      Sim
                    </span>
                    <span v-else>
                      <i class="fa-solid fa-lg fa-times-circle text-danger me-1"></i>
                      Não
                    </span>
                  </td>
                  <td>
                    <span v-if="item.rating">
                      <i class="fa-solid fa-user-doctor"></i>
                      <span v-if="item.rating > 3" class="fw-bold">
                        {{ item.rating }}.0
                        <i class="fa-solid fa-star text-warning"></i>
                      </span>
                      <span v-if="item.rating <= 3" class="fw-bold text-danger">
                        {{ item.rating }}.0
                        <i class="fa-solid fa-star text-danger"></i>
                      </span>
                    </span>
                    <span v-else class="text-muted">
                      Sem avaliação
                    </span>
                  </td>
                  <td>
                    <span v-if="item.testimonial" class="text-nowrap fw-bold" data-bs-toggle="tooltip"
                      data-bs-placement="bottom" :title="item?.testimonial" aria-describedby="tooltip302047"
                      :ref="`tooltip_testimonial_${index}`">
                      <i class="fa-solid fa-feather"></i>
                      Sim
                      <i class="fa-solid fa-lg fa-circle-info text-info ms-1"></i>
                    </span>
                    <span v-else class="text-muted">
                      Sem depoimento
                    </span>
                  </td>
                  <td>
                    <button class="btn  btn-sm btn-outline-success me-2" @click="handleValidateRating(index, true)">
                      <span v-if="item.loadingApproved" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                      <span v-else class="fas fa-check"></span>
                    </button>
                    <button class="btn  btn-sm btn-outline-danger" @click="handleValidateRating(index, false)">
                      <span v-if="item.loadingRepproved" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                      <span v-else class="fas fa-ban"></span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row" v-if="!loadingItems">
        <div class="col-12 text-end">
          <span class="text-muted">mostrando <b>{{ countItemsShowing }}</b> de
            <b>{{ countItemsTotal }}</b> resultado(s)</span>
        </div>
      </div>
      <div class="row justify-content-center" v-if="!loadingItems && moreItemsToLoad">
        <div class="col-2">
          <FormButton label="Carregar mais" :loading="loadingMoreItems" @onClick="loadMore" />
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import ReportedRatingsController from "./ReportedRatingsController";
export default ReportedRatingsController;
</script>

<style>
td.linkable:hover {
  text-decoration: underline;
}
</style>