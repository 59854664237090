<template>
  <div :class="{ 'card-header': isCard }">
    <div class="row align-items-center">
      <div class="col">
        <!-- <form> -->
        <div
          class="input-group input-group-flush input-group-merge input-group-reverse"
        >
          <template v-if="allowSearch">
            <input class="form-control list-search" type="search" id="search-input" placeholder="Pesquisar"
              v-on:keyup.enter="onSearch" />
            <span class="input-group-text">
              <i class="fe fe-search"></i>
            </span>
          </template>
        </div>
        <!-- </form> -->
        <slot name="bulkAction"></slot>
      </div>
      <slot name="filterSlot"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    allowSearch: {
      type: Boolean,
      default: true,
    },
    isCard: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      typingTimer: null,
    };
  },
  mounted() {},
  methods: {
    onSearch(event) {
      const $this = this;
      // clearTimeout(this.typingTimer);
      // $this.typingTimer = setTimeout(() => {
      $this.$emit("onSearch", event.target.value);
      // }, 300);
    },
  },
};
</script>

<style></style>
