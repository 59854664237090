<template>
  <div id="mission_detail">
    <Navbar current="Detalhes da sessão" :link="`${$paths.appointments.list}`" :linkName="'Sessões'" />
    <Sidebar />
    <Container>
      <Header title="Detalhes da Sessão">
        <template v-slot:button>
          <button v-if="canCancelFreeAppointment" @click="handleCancelAppointment()"
            class="btn btn-outline-danger ms-3">
            <i class="fa-solid fa-ban"></i>
            Cancelar gratuita
          </button>
          <button v-if="canUpdateAppointmentDate" @click="toggleModal(dateModalId)" class="btn btn-outline-secondary ms-3">
            <i class="far fa-calendar"></i>
            Alterar data
          </button>
        </template>
      </Header>
      <LoadingContainer v-if="loadingItems" />
      <div v-else>
        <div class="row">
          <div class="col-12 col-lg-7 col-xl-7 col-xxl-8">
            <div class="card">
              <ul class="nav nav-tabs ps-4 pt-3" id="myTab" role="tablist">
                <li class="nav-item">
                  <button class="nav-link active" id="information-tab" data-bs-toggle="tab"
                    data-bs-target="#information" type="button" role="tab" aria-controls="information"
                    aria-selected="true">
                    Informações
                  </button>
                </li>
                <li class="nav-item">
                  <button class="nav-link" id="log-tab" data-bs-toggle="tab" data-bs-target="#log" type="button"
                    role="tab" aria-controls="log" aria-selected="true">
                    Registro de interações
                  </button>
                </li>
                <li class="nav-item">
                  <button class="nav-link" id="review-tab" data-bs-toggle="tab" data-bs-target="#review" type="button"
                    role="tab" aria-controls="review" aria-selected="true">
                    Avaliações
                  </button>
                </li>

                <li class="nav-item"  v-if="item.invoice">
                  <button class="nav-link" id="nf-tab" data-bs-toggle="tab" data-bs-target="#nf" type="button"
                    role="tab" aria-controls="nf" aria-selected="true">
                    NF <i class="fa-solid fa-circle-check ms-1 text-success" v-if="item.invoice?.invoice_status == 'sent_by_email' || item.invoice?.invoice_status == 'issued'"></i>
                  </button>
                </li>
              </ul>
              <div class="tab-content">
                <!-- Information Tab -->
                <div class="tab-pane card-body fade show active" id="information" role="tabpanel"
                  aria-labelledby="information-tab">
                  <InfoComponent :item="item" />
                </div>
                <!-- Logs Tab -->
                <div class="tab-pane card-body fade show" id="log" role="tabpanel" aria-labelledby="log-tab">
                  <EventsComponent :item="item" />
                </div>
                <!-- Review -->
                <div class="tab-pane card-body fade show" id="review" role="tabpanel" aria-labelledby="review-tab">
                  <FeedbackComponent :item="item" />
                </div>
                 <!-- Invoice -->
                 <div class="tab-pane card-body fade show" id="nf" role="tabpanel" aria-labelledby="nf-tab">
                  <InvoiceComponent :item="item" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-5 col-xl-5 col-xxl-4">
            <ProfileComponent :item="item" @refundConcluded="getData()" />
          </div>
        </div>
      </div>
    </Container>
    <ModalComponent :title="'Alterar data da sessão'" :id="dateModalId">
      <template v-slot:body>
        <div class="mt-3">
          <TextInput label="Nova Data e horário" type="datetime-local" :model="$v.form.date"
            placeholder="Data e horário" />
          <p class="text-center text-danger">
            {{ errorMessage }}
          </p>
        </div>
      </template>
      <template v-slot:footer>
        <div>
          <FormButton label="Concluir" :loading="submitting" :disabled="$v.$invalid" class="btn w-15 btn-primary mb-3"
            @onClick="handleChangeDate" />
        </div>
      </template>
    </ModalComponent>
  </div>
</template>
<script>
import AppointmentDetailController from "./AppointmentDetailController";
import InvoiceComponent from "./InvoiceComponent.vue";
export default AppointmentDetailController;
</script>
