import { email, required, sameAs } from 'vuelidate/lib/validators';
export default {
  name: 'EditUser',
  mounted() {
    this.loadingItems = true
    this.getData()
  },
  data() {
    return {
      item: {},
      errorMessage: '',
      submitting: false,
      id: this.$route.params.id,
      form: {
        name: ''
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        // email,
      }
    }
  },
  methods: {
    async getData() {
      try {
        const res = await this.$userService.show(this.id)
        let data = res.data
        this.$set(this, 'form', { name: data.name })
        this.$set(this, 'item', data)
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingItems = false
      }
    },
    async submit() {
      try {
        this.submitting = true
        this.errorMessage = ''
        const values = this.form
        const res = await this.$userService.update(this.id, values)
        this.$eventBus.$emit('showToast', { title: 'Paciente alterado', msg: `Paciente alterado com sucesso` })
        this.$router.back()
      } catch (e) {
        this.errorMessage = e.message
      } finally {
        this.submitting = false
      }
    }

  }
};