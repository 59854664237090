import ListMixinController from "@/mixins/ListMixinController";
import {
  changeActive,
  changeApprove,
} from "@/views/Users/UserDetail/userFunctions";

export default {
  mixins: [ListMixinController],
  props: {
    propFilters: {
      type: Object,
      default: {},
    },
  },
  mounted() {
    this.loadingItems = true;
    this.filters = {
      ...this.filters,
      ...this.propFilters,
    };
    this.getData();
    // this.setupTableHead()
    this.$eventBus.$on("updatePsiList", (filters) => {
      if (filters) {
        this.loadingItems = true;
        this.filters = {
          ...this.filters,
          ...filters,
        };
      }
      this.getData();
    });
  },
  watch: {
    totalItemsRegistered(value) {
      this.$emit("totalItemsChanged", { total: value });
    },
  },
  data() {
    return {
      approveId: null,
      activeId: null,
      loadingApprove: false,
      loadingActive: false,
      columns: [
        {
          name: "ID",
          sortName: null,
          order: null,
        },
        {
          name: "Foto",
          sortName: null,
          order: null,
        },
        {
          name: "Nome & email",
          sortName: null,
          order: null,
        },
        {
          name: "Avaliação",
          sortName: null,
          order: null,
        },
        {
          name: "Online",
          sortName: null,
          order: null,
        },
        {
          name: "Imediata",
          sortName: null,
          order: null,
        },
        {
          name: "Psiapp+",
          sortName: null,
          order: null,
        },
        {
          name: "Criação",
          sortName: "created_at",
          order: null,
        },
        {
          name: "Valor",
          sortName: null,
          order: null,
        },
        {
          name: "Concluídas",
          sortName: "null",
          order: null,
        },
        {
          name: "Concluídas",
          sortName: "null",
          order: null,
        },
        {
          name: "Atalho",
          sortName: null,
          order: null,
        },
      ],
    };
  },
  methods: {
    // setupTableHead() {
    //   if (this.propFilters.approved == '0') {
    //     this.columns.splice(5, 0, {
    //       name: 'Aprovação',
    //       sortName: null,
    //       order: null
    //     })
    //   }
    // },
    handleChangeActive(index) {
      changeActive(this.items[index], () => {
        this.$eventBus.$emit("updatePsiList");
      });
    },
    handleChangeApprove(index) {
      changeApprove(this.items[index], () => {
        this.$eventBus.$emit("showToast", {
          title: !this.items[index].approved
            ? "Usuário aprovado"
            : "Usuário Desaprovado",
          msg: !this.items[index].approved
            ? `O Usuário foi aprovado com sucesso`
            : `O Usuário foi desaprovado`,
        });
        this.$eventBus.$emit("updatePsiList");
      });
    },
    handleDetail(event, id) {
      event.preventDefault();
      const path = this.$router.resolve({
        path: `${this.$paths.psychologists.detail}/${id}`,
      });
      window.open(path.href, "_blank");
    },
    fetchPromise() {
      console.log(this.filters);
      let f = JSON.parse(
        JSON.stringify({
          ...this.filters,
          orderBy: this.columns.filter(
            (r) => r.order != null && r.sortName != null
          ),
          type: "psychologist",
        })
      );
      if (f.onlyPremium) {
        f.subscriber_only = 1;
      }
      delete f.onlyPremium;
      return this.$userService.list(f);
    },
    prepareResponse(data) {
      return data.map((elem) => {
        return this.$userService.preparePsi(elem);
      });
    },
  },
};
