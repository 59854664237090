<template>
  <div id="psychologists">
    <div class="card">
      <SearchBar @onSearch="search" />
      <LoadingContainer v-if="loadingItems" />
      <div v-else>
        <EmptyState v-if="!items.length" :totalItemsRegistered="totalItemsRegistered">
          <template v-slot:noItemsRegistered>
            <span class="fa-3x fa-solid fa-user-md text-black-50 mb-2"></span>
            <p>Nenhum psicólogo criado</p>
          </template>
        </EmptyState>
        <div v-else class="table-responsive">
          <table ref="myTable" class="table table-sm card-table table-hover cursor-pointer">
            <TableHead :columns="columns" v-on:sort="sort" />
            <tbody class="list">
              <router-link tag="tr" v-for="(item, index) in items" v-bind:key="'tr-' + index"
                :to="`${$paths.psychologists.detail}/${item.id}`">
                <!-- <tr v-for="(item, index) in items" v-bind:key="'tr-' + index"> -->
                <td>
                  {{ item.id }}
                </td>
                <td>
                  <div class="avatar" v-if="item.profile_image && item.profile_image != '1'">
                    <img :src="`${$baseImageUrl}${item.profile_image}`" alt="..." class="avatar-img rounded-circle" />
                  </div>
                  <div v-else class="avatar">
                    <!-- <div v-else class="avatar" :class="{
                    'avatar-online': item.online,
                    'avatar-offline': !item.online,
                    'avatar-deleted': item.deleted_at,
                  }"> -->
                    <img src="@/assets/img/avatar_placeholder.svg" alt="..." class="avatar-img rounded-circle" />
                  </div>
                </td>
                <td>
                  <span>
                    <span class="fw-bold">
                      {{ item.name }}
                      <i v-if="item.subscription?.status == 'active'  || item.subscription?.status == 'trialing'" class="fa-solid fa-circle-plus text-info"></i>
                    </span>
                    <span class="small fw-bold text-danger" v-if="item.deleted_at">(Excluído)</span>
                    <!-- <span v-if="!item.avg_rating" class="badge bg-light text-muted mb-1">
                      <i class="fa-solid fa-star"></i> sem avaliações
                    </span>
                    <span v-if="item.avg_rating" class="badge bg-warning-soft fw-bold mb-1">
                      <i class="fa-solid fa-star"></i> {{ item.avg_rating }}
                    </span> -->
                  </span>
                  <br>
                  <span>
                    {{ item.email }}
                  </span>


                </td>
                <td>
                  <span v-if="!item.avg_rating" class="mb-1">
                    <i class="fa-solid fa-star text-muted"></i> sem avaliações
                  </span>
                  <span v-if="item.avg_rating" class="fw-bold mb-1">
                    <i class="fa-solid fa-star text-warning"></i> {{ item.avg_rating }}
                  </span>
                </td>
                <td>
                  <template v-if="item.online">
                    <i class="fa-solid fa-circle fa-xs text-success me-2"></i>
                    <i class="fa-regular fa-calendar"></i>
                    <span class="fw-bold">
                      Disponível
                    </span>
                  </template>
                  <template v-if="!item.online">
                    <i class="fa-solid fa-circle fa-xs text-muted me-2"></i>
                    <i class="fa-regular fa-calendar"></i>
                    <span class="">
                      Indisponível
                    </span>
                  </template>
                </td>
                <!-- Imediate -->
                <td>
                  <template v-if="item.imediate">
                    <i class="fa-solid fa-circle fa-xs text-success me-2"></i>
                    <i class="fa-solid fa-bell-concierge"></i>
                    <span class="fw-bold">
                      Disponível
                    </span>
                  </template>
                  <template v-if="!item.imediate">
                    <i class="fa-solid fa-circle fa-xs text-muted me-2"></i>
                    <i class="fa-solid fa-bell-concierge"></i>
                    <span class="">
                      Indisponível
                    </span>
                  </template>
                </td>
                <td>
                  <span v-if="item.subscription?.status == 'active'  || item.subscription?.status == 'trialing'" class="fw-bold">
                    <i class="fa-solid fa-circle-check text-success"></i>
                    Assinante
                  </span>
                  <span v-else>
                    <i class="fa-regular fa-clock"></i>
                    Não assinante
                  </span>
                </td>
                <td>
                  <time>{{
                    $moment(item.created_at).format("DD/MM/YYYY")
                  }}</time>
                </td>
                <td>
                  R${{ item.appointment_price }}
                </td>
                <td :class="{ 'fw-bold': item.appointments_as_psychologist_count, }">
                  <i class="fa-regular fa-calendar me-2"></i>
                  {{ item.appointments_as_psychologist_count }}
                </td>
                <td :class="{ 'fw-bold': item.appointments_as_psychologist_count, }">
                  <i class="fa-solid fa-bell-concierge me-2"></i>
                  {{ item.appointments_as_psychologist_count }}
                </td>
                <td class="text-end">
                  <button v-if="!item?.deleted_at && !item.approved && propFilters.approved == '0'"
                    @click="handleChangeApprove(index)" class="btn btn-sm btn-success lift me-2">
                    <i class="fa-solid fa-check-circle text-white"></i>
                  </button>
                  <button class="btn btn-sm btn-white lift" @click="handleDetail($event, item.id)">
                    <span class="fe fe-arrow-up-right"></span>
                  </button>
                </td>
                <!-- <template v-if="!item?.deleted_at">
                  <td v-if="propFilters.approved == '0'">
                    <button
                      v-if="item.approved"
                      @click="handleChangeApprove(index)"
                      class="btn btn-danger btn-sm"
                    >
                      <i class="fa-solid fa-ban"></i>
                      Desaprovar
                    </button>
                    <button
                      v-else
                      @click="handleChangeApprove(index)"
                      class="btn btn-success btn-sm"
                    >
                      <i class="fa-solid fa-check-circle"></i>
                      Aprovar
                    </button>
                  </td>
                  <td class="text-end">
                    <button
                      v-if="item.active"
                      @click="handleChangeActive(index)"
                      class="btn btn-outline-secondary"
                    >
                      <i class="fa-solid fa-lock"></i>
                    </button>
                    <button
                      v-else
                      @click="handleChangeActive(index)"
                      class="btn btn-outline-secondary"
                    >
                      <i class="fa-solid fa-lock-open"></i>
                    </button>
                  </td>
                </template>
                <template v-else>
                  <td v-if="propFilters.approved == '0'"></td>
                  <td></td>
                </template> -->
                <!-- </tr> -->
              </router-link>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row" v-if="!loadingItems">
      <div class="col-12 text-end">
        <span class="text-muted">mostrando <b>{{ countItemsShowing }}</b> de
          <b>{{ countItemsTotal }}</b> resultado(s)</span>
      </div>
    </div>
    <div class="row justify-content-center" v-if="!loadingItems && moreItemsToLoad">
      <div class="col-2">
        <FormButton label="Carregar mais" :loading="loadingMoreItems" @onClick="loadMore" />
      </div>
    </div>
  </div>
</template>
<script>
import PsychologistsListController from "./PsychologistsListController";
export default PsychologistsListController;
</script>
