import PsychologistsList from './PsychologistsList/PsychologistsList.vue'
export default {
  name: 'Psychologists',
  watch: {
    "$route.params"(value) {
      this.selectedTab = value.tab || 'todos'
    }
  },
  mounted() {
  },
  computed: {
  },
  data() {
    return {
      onlyPremium: false,
      tabs: [
        {
          id: 'todos',
          name: 'Todos',
          totalCount: 0
        },
        {
          id: 'aprovados',
          name: 'Aprovados',
          totalCount: 0
        },
        {
          id: 'reprovados',
          name: 'Não aprovados',
          totalCount: 0
        },
        {
          id: 'online',
          name: 'Agenda online',
          totalCount: 0
        },
        {
          id: 'imediata',
          name: 'Imediata',
          totalCount: 0
        },
      ],
      totalPsiCount: 0,
      selectedTab: this.$route.params.tab || 'todos',
    };
  },
  methods: {
    totalChanged(event, key) {
      let t = [...this.tabs]
      t.map(r => {
        if (r.id === key) {
          r.totalCount = event.total
        }
      })
      this.$set(this, 'tabs', t)
      if (!this.onlyPremium && key == 'todos') {
        this.totalPsiCount = event.total
      }
    },
    handlePremiumFilterChanged() {
      this.$eventBus.$emit("updatePsiList", { onlyPremium: this.onlyPremium });
    }
  },

  components: {
    PsychologistsList
  }
};
