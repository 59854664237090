<template>
  <div class="row">
    <div class="col-12">
      <table class="table table-borderless table-hover table-sm">
        <thead>
          <tr>
            <th scope="col">Dia da semana</th>
            <th scope="col">Início do turno</th>
            <th scope="col">Fim do turno</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(hour, index) in item.hours" v-bind:key="'hr-' + index">
            <td>{{ hour.weekday | getWeekDay }}</td>
            <td class="fw-bold">
              {{ hour.start_time.substr(0, 5) }}
            </td>
            <td class="fw-bold">
              {{ hour.end_time.substr(0, 5) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: "PsiDetailSchedules",
  props: {
    item: {
      type: Object,
      default: () => { },
    },
  },
}
</script>