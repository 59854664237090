<template>
  <div id="nf">
    <div class="row">
      <div class="col">
        <h2 class="fw-bold mb-4">
            Detalhes da Nota Fiscal</strong>
          </h2>

        <div class="list-group list-group-flush my-n3">

            <div class="list-group-item" v-if="item.invoice?.invoice_number">
                <div class="row align-items-center">
                  <div class="col text-start">
                    <p class="text-muted mb-0">
                      Número
                    </p>
                  </div>
                  <div class="col-auto">
                    <p class="mb-0">
  
                      <span  class="fw-bold">#{{item.invoice?.invoice_number}}</span>
  
                    </p>
                  </div>
                </div>
              </div>
            <div class="list-group-item">
              <div class="row align-items-center">
                <div class="col text-start">
                  <p class="text-muted mb-0">
                    Status
                  </p>
                </div>
                <div class="col-auto">
                  <p class="mb-0">

                    <span  class="fw-bold" :class="getStatusClass(item.invoice?.invoice_status)"
                    >{{ translatedStatus(item.invoice?.invoice_status) }}</span>

                  </p>
                </div>
              </div>
            </div>

            <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col text-start">
                    <p class="text-muted mb-0">
                      Valor
                    </p>
                  </div>
                  <div class="col-auto">
                    <p class="mb-0">
  
                      <span  class="fw-bold">R${{item.invoice?.invoice_value}}</span>
  
                    </p>
                  </div>
                </div>
              </div>

              <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col text-start">
                    <p class="text-muted mb-0">
                      ISS
                    </p>
                  </div>
                  <div class="col-auto">
                    <p class="mb-0">
  
                      <span  class="fw-bold">R${{item.invoice?.iss_value}}</span>
  
                    </p>
                  </div>
                </div>
              </div>


            <div class="list-group-item" v-if="item.invoice?.invoice_issue_date">
                <div class="row align-items-center">
                  <div class="col text-start">
                    <p class="text-muted mb-0">
                      Data de emissão
                    </p>
                  </div>
                  <div class="col-auto">
                    <p class="mb-0">
  
                      <span  class="fw-bold">{{ $moment(item.invoice?.invoice_issue_date).format("DD/MM/YYYY") }}</span>

                    </p>
                  </div>
                </div>
              </div>


            <div class="list-group-item" v-if="item.invoice?.invoice_pdf">
                <div class="row align-items-center">
                  <div class="col text-start">
                    <p class="text-muted mb-0">
                      PDF
                    </p>
                  </div>
                  <div class="col-auto">
                    <p class="mb-0">
  
                      <span>
                        <a  class="fw-bold" :href="`${item.invoice?.invoice_pdf}`" target="_blank"> <i class="fa-solid fa-file-pdf"></i> PDF </a>
                      </span>
  
                    </p>
                  </div>
                </div>
              </div>



            <div class="list-group-item" v-if="item.invoice?.invoice_xml">
                <div class="row align-items-center">
                  <div class="col text-start">
                    <p class="text-muted mb-0">
                      XML
                    </p>
                  </div>
                  <div class="col-auto">
                    <p class="mb-0">
  
                      <span>
                        <a  class="fw-bold" :href="`${item.invoice?.invoice_xml}`" target="_blank"> <i class="fa-regular fa-file-lines"></i> XML </a>
                      </span>
  
                    </p>
                  </div>
                </div>
              </div>

              <div class="list-group-item" v-if="item.invoice?.invoice_status_reason">
                <div class="row align-items-center">
                  <div class="col text-start">
                    <p class="text-muted mb-0">
                      Motivo do status
                    </p>
                  </div>
                  <div class="col-auto">
                    <p class="mb-0">
  
                      <span  class="fw-bold">
                        {{ item.invoice?.invoice_status_reason }}
                      </span>
  
                    </p>
                  </div>
                </div>
              </div>


              <div class="list-group-item" v-if="item.invoice?.invoice_cancel_date">
                <div class="row align-items-center">
                  <div class="col text-start">
                    <p class="text-muted mb-0">
                      Data de cancelamento
                    </p>
                  </div>
                  <div class="col-auto">
                    <p class="mb-0">
  
                      <span  class="fw-bold">
                        {{ $moment(item.invoice?.invoice_cancel_date).format("DD/MM/YYYY") }}
                      </span>

  
                    </p>
                  </div>
                </div>
              </div>
  
  
              


              

        </div>







        
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'InvoiceComponent',
  props: {
    item: Object,
    default: {}
  },
  methods: {
    translatedStatus(invoiceStatus) {
      switch (invoiceStatus) {
        case 'pending':
          return 'Pendente';
        case 'processing':
          return 'Em processamento';
        case 'issued':
          return 'Emitido';
        case 'failed':
          return 'Falhou';
        case 'cancelling_process':
          return 'Em processo de cancelamento';
        case 'failed_to_cancel':
          return 'Falha em cancelar';
        case 'will_not_issue':
          return 'Não vai emitir';
        case 'sent_by_email':
          return 'Enviado por email';   
        case 'unknown':
          return 'Desconhecido';   
        case 'cancelled':        
          return 'Cancelado';
        default:
          return invoiceStatus; // Handle unknown status
      }
    },
    getStatusClass(invoiceStatus) {
      switch (invoiceStatus) {
        case 'pending':
          return 'text-warning';
        case 'processing':
          return 'text-info';
        case 'issued':
            return 'text-success';
        case 'failed':
            return 'text-danger';
        case 'cancelling_process':
            return 'text-warning';
        case 'failed_to_cancel':
            return 'text-warning';
        case 'will_not_issue':
            return 'text-warning';
        case 'sent_by_email':
            return 'text-success';
        case 'unknown':
            return 'text-info';
        case 'cancelled':        
            return 'text-danger';
        default:
          return '';
      }
    },
  },
}
</script>
