import moment from 'moment';
import { email, required, sameAs, minLength, helpers, minValue, maxValue, withMessage } from 'vuelidate/lib/validators';
export default {
  name: 'AddCoupon',
  mounted() { },
  data() {
    return {
      errorMessage: '',
      submitting: false,
      form: {
        code: '',
        discount: '',
        company_name: '',
      },
    };
  },
  validations() {
    return {
      form: {
        code: {
          required,
          minLength: minLength(6),
          validChars: (value) => {
            var pattern = new RegExp('^[a-zA-Z0-9]+$');
            return pattern.test(value)
          },
        },
        company_name: {
          required,
          minLength: minLength(6),
        },
        discount: {
          required,
          maxValue: maxValue(30),
          minValue: minValue(1),
        },
      }
    }
  },
  methods: {
    async submit() {
      try {
        this.submitting = true
        this.errorMessage = ''
        const values = JSON.parse(JSON.stringify(this.form))
        const params = {
          code: values.code.toUpperCase(),
          discount_percentage: values.discount,
          company_name: values.company_name
        }
        const res = await this.$partnerService.create(params)
        this.$eventBus.$emit('showToast', { title: 'Empresa criada', msg: `Novo código de empresa parceira adicionado` })
        this.$router.push({ path: `${this.$paths.partners.list}` })
      } catch (e) {
        this.errorMessage = e.message
      } finally {
        this.submitting = false
      }
    },
  }
};