<template>
  <div id="users">
    <Navbar current="Pacientes" />
    <Sidebar />
    <Container col="col-12">
      <Header title="Pacientes" :isSubHeader="false" subtitle="Registro de todos os pacientes"
        :iconClass="'fa-solid fa-user-friends text-primary'" :totalCount="totalItemsRegistered">
        <template v-slot:button>
          <button class="btn btn-primary" @click="exportModal.toggle()">
            <i class="fa-solid fa-file-arrow-down me-2"></i>
            Exportar
          </button>
        </template>
      </Header>
      <div class="card">
        <SearchBar @onSearch="search" />
        <LoadingContainer v-if="loadingItems" />
        <div v-else>
          <EmptyState v-if="!items.length" :totalItemsRegistered="totalItemsRegistered">
            <template v-slot:noItemsRegistered>
              <span class="fa-3x fa-solid fa-user-friends text-black-50 mb-2"></span>
              <p>Nenhum usuário criado</p>
            </template>
          </EmptyState>
          <div v-else class="table-responsive">
            <table ref="myTable" class="table table-sm table-nowrap card-table table-hover cursor-pointer">
              <TableHead :columns="columns" v-on:sort="sort" />
              <tbody class="list">
                <tr v-for="(item, index) in items" v-bind:key="'tr-' + index" @click="handleDetail(item.id, index)">
                  <td>
                    <img src="@/assets/img/avatar_placeholder.svg" class="avatar-img avatar-xs rounded-circle" alt="" />
                  </td>
                  <td>
                    {{ item.name }}
                    <h4 v-if="item.deleted_at" class="text-danger fw-bold">
                      Conta excluída
                    </h4>
                  </td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.appointments_as_user_count }}</td>
                  <td class="text-end">
                    <time>{{
                      $moment(item.created_at).format("DD/MM/YYYY")
                      }}</time>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row" v-if="!loadingItems">
        <div class="col-12 text-end">
          <span class="text-muted">mostrando <b>{{ countItemsShowing }}</b> de
            <b>{{ countItemsTotal }}</b> resultado(s)</span>
        </div>
      </div>
      <div class="row justify-content-center" v-if="!loadingItems && moreItemsToLoad">
        <div class="col-2">
          <FormButton label="Carregar mais" :loading="loadingMoreItems" @onClick="loadMore" />
        </div>
      </div>
    </Container>
    <ModalComponent :title="'Exportar usuários'" id="exportModal">
      <template v-slot:body>
        <div class="row">
          <div class="col-12">
            <label for="" class="form-label text-muted">Selecione o período</label>
          </div>
          <div class="col-12">
            <Datepicker format="DD/MM/YYYY" v-model="dateRange" placeholder="Selecione o período" range />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <button class="btn btn-primary" @click="handleExport" :disabled="loading">
          <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <template v-else>
            <i class="fa-solid fa-file-arrow-down me-2"></i>
            Exportar
          </template>
        </button>
      </template>
    </ModalComponent>
  </div>
</template>
<script>
import UsersController from "./UsersController";
export default UsersController;
</script>
