import ListMixinController from "../../mixins/ListMixinController";
import { Tooltip } from "bootstrap";
export default {
  name: "Ratings",
  mixins: [ListMixinController],
  mounted() {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    });
    this.loadingItems = true;
    this.getData();
  },
  data() {
    return {
      columns: [
        {
          name: "Tipo da sessão",
          sortName: null,
          order: null,
        },
        {
          name: "Data da sessão",
          sortName: "date",
          order: "DESC",
        },
        {
          name: "Paciente",
          sortName: null,
          order: null,
        },
        {
          name: "Sobre o Psi",
          sortName: null,
          order: null,
        },
        {
          name: "Depoimento",
          sortName: null,
          order: null,
        },
        {
          name: "Video chamada",
          sortName: null,
          order: null,
        },
        {
          name: "Psicólogo",
          sortName: null,
          order: null,
        },
        {
          name: "Video chamada",
          sortName: null,
          order: null,
        },
        {
          name: "Atalho",
          sortName: null,
          order: null,
        },
      ],
    };
  },
  methods: {
    prepareResponse(data) {
      return data.map((elem) => {
        return {
          ...elem,
          psi_rating: elem.ratings.find((r) => r.rating_by == "psychologist"),
          user_rating: elem.ratings.find((r) => r.rating_by == "user"),
        };
      });
    },
    fetchPromise() {
      let f = {
        ...this.filters,
        status: "concluido",
        orderBy: this.columns.filter(
          (r) => r.order != null && r.sortName != null
        ),
      };
      return this.$appointmentsService.list(f);
    },
    handleDetail(event, id) {
      event.preventDefault();
      const tooltips = document.getElementsByClassName("tooltip");
      if (tooltips && tooltips.length) {
        tooltips[0].remove();
      }
      const path = this.$router.resolve({
        path: `${this.$paths.appointments.detail}/${id}`,
      });
      window.open(path.href, "_blank");
      // this.$router.push({ path: `${this.$paths.appointments.detail}/${id}` });
    },
  },
};
