import ListMixinController from "@/mixins/ListMixinController";

export default {
  mixins: [ListMixinController],
  mounted() {
    this.loadingItems = true;
    this.getData();
  },
  data() {
    return {
      columns: [
        {
          name: "Código do código",
          sortName: null,
          order: null,
        },
        {
          name: "Data de criação",
          sortName: null,
          order: null,
        },
        {
          name: "Qtd utilizada",
          sortName: null,
          order: null,
        },
        {
          name: "Data de validade",
          sortName: null,
          order: null,
        },
        {
          name: "Status",
          sortName: null,
          order: null,
        },
      ],
    };
  },
  methods: {
    fetchPromise() {
      return this.$couponsService.list({
        ...this.filters,
        orderBy: this.columns.filter(
          (r) => r.order != null && r.sortName != null
        ),
      });
    },
    handleDetail(id, index) {
      this.$router.push({ path: `${this.$paths.coupons.detail}/${id}` });
    },
    prepareResponse(data) {
      return data.map((elem) => {
        return this.$couponsService.prepareCoupon(elem);
      });
    },
  },
};
