<template>
  <div>
    <div class="row">
      <div class="col-12 col-xl-6">
        <TextInput :readOnly="true" label="CRP" :value="item.crp" />
      </div>
      <div class="col-12 col-xl-6">
        <TextInput :readOnly="true" label="e-Psi" :value="item.epsi" />
      </div>
      <div class="col-12 col-xl-12">
        <TextInput :readOnly="true" label="Linkedin" :value="item.linkedin" />
      </div>
      <div class="col-12 col-xl-12">
        <TextAreaInput :readOnly="true" label="Bio" :value="item.bio || ''" />
      </div>
      <div class="col-12 col-xl-8">
        <TextInput :readOnly="true" label="Formação" :value="item.education" />
      </div>
      <div class="col-12 col-xl-4">
        <TextInput :readOnly="true" label="Valor da consulta" :value="item.formatted_price" />
      </div>
      <div class="col-12 col-xl-4">
        <TextInput :readOnly="true" label="Tempo de experiência" :value="`${item.years_exp} anos`" />
      </div>
      <div class="col-12 col-xl-4">
        <TextInput :readOnly="true" label="Nascimento" :value="item.formatted_birthdate" />
      </div>
      <div class="col-12 col-xl-4">
        <TextInput :readOnly="true" label="Gênero" :value="item.formatted_gender" />
      </div>
      <div class="col-12 col-xl-12">
        <TextAreaInput :readOnly="true" label="CV" :value="item.cv || ''" />
      </div>
      <hr class="my-3" />
      <h3 class="mt-4">Assuntos</h3>
      <div class="col-12">
        <h2>
          <span class="badge bg-white border text-secondary me-2 mb-2" v-for="(oc, index) in item.occupations"
            v-bind:key="'oc-' + index">
            {{ oc.occupation.title }}
          </span>
        </h2>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PsiDetailProfile",
  props: {
    item: {
      type: Object,
      default: () => { },
    },
  },
}
</script>