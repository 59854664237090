<template>
  <div id="contracts">
    <Navbar current="Contratos" />
    <Sidebar />
    <Container col="col-12">
      <Header title="Contratos" :isSubHeader="false" subtitle="" :iconClass="'fa-solid fa-file-contract text-primary'"
        :totalCount="null" />
      <div class="card">
        <div class="table-responsive">
          <table class="
                table table-sm table-nowrap
                card-table
                table-hover
                cursor-pointer
              ">
            <TableHead :columns="columns" />
            <tbody class="list">
              <router-link :to="item.path" tag="tr" v-for="(item, index) in items" v-bind:key="'tr-' + index">
                <td class="">
                  {{ item.name }}
                </td>
              </router-link>
            </tbody>
          </table>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import ContractsController from "./ContractsController";
export default ContractsController;
</script>
