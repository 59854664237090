import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userSession: JSON.parse(localStorage.getItem(process.env.VUE_APP_API_SESSION_KEY)),
    token: null,
    userInfo: {
      chatBadge: false,
      appointmentBadge: false,
    }
  },
  mutations: {
    saveSession(state, newSession) {
      state.userSession = newSession
      console.log('SAVING SESSION', process.env.VUE_APP_API_SESSION_KEY, newSession)
      localStorage.setItem(process.env.VUE_APP_API_SESSION_KEY, JSON.stringify(newSession))
    },
    saveToken(state, token) {
      console.log('saving token...', token)
      state.token = token
      localStorage.setItem(process.env.VUE_APP_API_TOKEN_KEY, token)
    },
    clearSession(state) {
      state.userSession = null
      state.token = null
      localStorage.removeItem('token')
      localStorage.removeItem(process.env.VUE_APP_API_SESSION_KEY)
    },
    setUserInfo(state, data) {
      const userInfo = {
        ...state.userInfo,
        ...data
      }
      state.userInfo = userInfo
      localStorage.setItem(process.env.VUE_APP_USER_INFO_KEY, JSON.stringify(userInfo))
    }
  },
  actions: {
    syncSessionWithApi: async ({ commit }, { token } = { token: null }) => {

    },
    saveSession: async ({ commit }, data) => {
      console.log('session', data)
      commit('saveSession', data.data)
      commit('saveToken', data.access_token)
    },
    saveUserInfo: async ({ commit }, data) => {
      commit('setUserInfo', data)
    }
  },
  modules: {
  }
})
