import ListMixinController from "../../mixins/ListMixinController";
import { Tooltip } from "bootstrap";
import { approveRating, repproveRating } from "../Ratings/ratingFunctions";
export default {
  name: "Ratings",
  mixins: [ListMixinController],
  mounted() {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    });
    this.loadingItems = true;
    this.getData();
  },
  data() {
    return {
      columns: [
        {
          name: "#",
          sortName: null,
          order: null,
        },
        {
          name: "Data do report",
          sortName: null,
          order: null,
        },
        {
          name: "Motivo",
          sortName: null,
          order: null,
        },
        {
          name: "Sessão",
          sortName: null,
          order: null,
        },
        {
          name: "Psicólogo",
          sortName: null,
          order: null,
        },
        {
          name: "Problema técnico",
          sortName: null,
          order: null,
        },
        {
          name: "Realizou a chamada",
          sortName: null,
          order: null,
        },
        {
          name: "Avaliação do psi",
          sortName: null,
          order: null,
        },
        {
          name: "Depoimento",
          sortName: null,
          order: null,
        },
        {
          name: "",
          sortName: null,
          order: null,
        },
      ],
      selectedRatings: [],
    };
  },
  methods: {
    prepareResponse(data) {
      return data.map((elem) => this.$ratingsService.prepareRating(elem));
    },
    fetchPromise() {
      let f = {
        ...this.filters,
        review_requested: 1,
        rating_by: "user",
        orderBy: this.columns.filter(
          (r) => r.order != null && r.sortName != null
        ),
      };
      return this.$ratingsService.list(f);
    },
    handleValidateRating(index, approved) {
      const item = this.items[index];
      if (approved) {
        approveRating(item, (res) => {
          this.handleRatingValidated(index);
        });
      } else {
        repproveRating(item, (res) => {
          this.handleRatingValidated(index);
        });
      }
    },
    handleRatingValidated(index) {
      this.$eventBus.$emit("showToast", {
        title: "Avaliação salva",
        msg: `Informações salvas com sucesso`,
      });
      this.items.splice(index, 1);
    },
    goToAppointment(appointmentId) {
      if (!appointmentId) {
        return;
      }
      const path = this.$router.resolve({
        path: `${this.$paths.appointments.detail}/${appointmentId}`,
      });
      window.open(path.href, "_blank");
    },
  },
};
