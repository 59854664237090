<template>
  <div id="settings">
    <Navbar :current="settingsInfo.title" :link="$paths.contracts.list" :linkName="'Contratos'" />
    <Sidebar />
    <Container col="col-12">
      <Header :isSubHeader="false" :title="settingsInfo.title" />

      <div class="row">
        <div class="col-10 offset-1">
          <LoadingContainer v-if="loadingItems" />
          <div v-else>
            <div class="mb-4">
              <EditorInput :model="$v.text" style="height: calc(50vh)" />
            </div>
            <div class="text-center mt-6" v-if="errorMessage">
              <span class="text-danger">{{ errorMessage }}</span>
            </div>
            <FormButton label="Concluir" :loading="submitting" :disabled="$v.$invalid" class="mt-5" @onClick="submit" />
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import ContractDetailController from "./ContractDetailController";
export default ContractDetailController;
</script>
