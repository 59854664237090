import { Modal } from "bootstrap";
import ListMixinController from "../../mixins/ListMixinController";
export default {
  name: "Users",
  mixins: [ListMixinController],
  mounted() {
    this.loadingItems = true;
    this.getData();

    this.exportModal = new Modal(document.getElementById("exportModal"), {
      backdrop: "static",
      keyboard: false,
    });
  },
  data() {
    return {
      loading: false,
      exportModal: null,
      dateRange: [
        this.$moment().startOf("month").toDate(),
        this.$moment().endOf("month").toDate(),
      ],
      columns: [
        {
          name: "",
          sortName: null,
          order: null,
        },
        {
          name: "Nome",
          sortName: null,
          order: null,
        },
        {
          name: "Email",
          sortName: null,
          order: null,
        },
        // {
        //   name: 'Método de pagamento',
        //   sortName: null,
        //   order: null
        // },
        {
          name: "Sessões",
          sortName: null,
          order: null,
        },
        {
          name: "Data de criação",
          sortName: null,
          order: null,
        },
      ],
    };
  },
  methods: {
    fetchPromise() {
      return this.$userService.list({
        ...this.filters,
        type: "user",
        orderBy: this.columns.filter(
          (r) => r.order != null && r.sortName != null
        ),
      });
    },
    handleDetail(id) {
      this.$router.push({ path: `${this.$paths.users.detail}/${id}` });
    },
    async handleExport() {
      try {
        this.loading = true;
        let params = {
          from: this.$moment(this.dateRange[0]).format("YYYY-MM-DD"),
          to: this.$moment(this.dateRange[1]).format("YYYY-MM-DD"),
        };
        let res = await this.$userService.exportUsers(params);

        const link = document.createElement("a");
        link.href = res.data.url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        this.exportModal.toggle();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
