import RatingsService from '@/services/RatingsService';
import { EventBus } from '@/utils/EventBus';
export const approveRating = (item, callBack) => {
  const ratingsService = new RatingsService()
  EventBus.$emit("showConfirmationAlert", {
    title: "Deseja aprovar esta avaliação?",
    message: "Ao aprovar a avliação, ela passará a ser visível para os usuários que verem o perfil desse psi. Deseja continuar?",
    confirmCallback: async () => {
      try {
        await ratingsService.validateRating(item.id, true)
        callBack()
      } catch (e) {
        console.log(e);
      } finally {
      }
    },
  });
}
export const repproveRating = (item, callBack) => {
  const ratingsService = new RatingsService()
  EventBus.$emit("showConfirmationAlert", {
    title: "Deseja remover esta avaliação?",
    message: "Ao remover a avliação, ela não será visível para os usuários que verem o perfil desse psi. Deseja continuar?",
    confirmCallback: async () => {
      try {
        await ratingsService.validateRating(item.id, false)
        callBack()
      } catch (e) {
        console.log(e);
      } finally {
      }
    },
  });
}