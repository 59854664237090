<template>
  <div id="ratings">
    <Navbar current="Avaliações" />
    <Sidebar />
    <Container col="col-12">
      <Header title="Avaliações" :isSubHeader="false" subtitle="Registro de avaliações das sessões"
        :iconClass="'fa-solid fa-star text-primary'" :totalCount="totalItemsRegistered">
      </Header>

      <div class="card">
        <SearchBar @onSearch="search" />
        <LoadingContainer v-if="loadingItems" />
        <div v-else>
          <EmptyState v-if="!items.length" :totalItemsRegistered="totalItemsRegistered">
            <template v-slot:noItemsRegistered>
              <span class="fa-3x fa-regular fa-star-half-stroke text-black-50 mb-2"></span>
              <p>Nenhuma avaliação encontrada</p>
            </template>
          </EmptyState>
          <div v-else class="table-responsive">
            <table ref="myTable" class="table table-sm table-nowrap card-table table-hover cursor-pointer">
              <TableHead :columns="columns" v-on:sort="sort" />
              <tbody class="list">
                <router-link tag="tr" v-for="(item, index) in items" v-bind:key="'tr-' + index"
                  :to="`${$paths.appointments.detail}/${item.id}`">
                  <!-- <tr v-for="(item, index) in items" v-bind:key="'tr-' + index" @click="handleDetail(item.id, index)"
                  :to="`${$paths.appointments.detail}/${item.id}`"> -->
                  <td>
                    <i v-if="item.type == 'scheduled'" class="fa-regular fa-calendar"></i>
                    <i v-else class="fa-solid fa-bell-concierge"></i>
                    {{ item.type == "scheduled" ? "Agendada" : "Imediata" }}
                  </td>
                  <td class="">
                    <time>{{ $moment(item.date).format("DD/MM/YY - HH:mm") }}</time>
                  </td>
                  <td>
                    <div class="avatar avatar-xs align-middle me-2">
                      <img src="@/assets/img/avatar_placeholder.svg" alt="..." class="avatar-img rounded-circle" />
                    </div>
                    <span>
                      ({{ item.user.id }}) <span class="fw-bold">{{ item.user.name }}</span>
                      <span v-if="item.user.deleted_at" class="text-danger fw-bold">
                        (Excluído)
                      </span>
                    </span>
                  </td>
                  <!-- <td class="text-nowrap"
                    v-if="item.user_rating"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    :title="item?.user_rating?.description"
                    aria-describedby="tooltip302047"
                    :ref="`tooltip_user_${index}`"
                  >
                   <RatingStars :maxRating="5" :rating="item.user_rating?.rating || 0" />
                   <i v-if="item.user_rating?.description" class="fa-solid fa-lg fa-envelope-open-text ms-3"></i>
                    {{ item.user_rating.rating }}
                  </td>
                  <td v-else class="text-muted">Sem avaliação</td> -->
                  <td>
                    <span v-if="item.user_rating?.psi_rating">
                      <i class="fa-solid fa-user-doctor"></i>
                      <span v-if="item.user_rating?.psi_rating > 3" class="fw-bold">
                        {{ item.user_rating?.psi_rating }}.0
                      </span>
                      <span v-if="item.user_rating?.psi_rating <= 3" class="fw-bold text-danger">
                        {{ item.user_rating?.psi_rating }}.0
                      </span>
                      <i v-if="item.user_rating?.psi_rating > 3" class="fa-solid fa-star text-warning"></i>
                      <i v-if="item.user_rating?.psi_rating <= 3" class="fa-solid fa-star text-danger"></i>
                    </span>
                    <span v-if="!item.user_rating?.psi_rating" class="">
                      Sem avaliação
                    </span>
                  </td>
                  <td>
                    <span v-if="item.user_rating?.testimonial" class="text-nowrap fw-bold" data-bs-toggle="tooltip"
                      data-bs-placement="bottom" :title="item?.user_rating?.testimonial" aria-describedby="tooltip302047"
                      :ref="`tooltip_user_${index}`">
                      <i class="fa-solid fa-feather"></i>
                      Sim
                      <i v-if="item.user_rating?.description" class="fa-solid fa-lg fa-circle-info text-info ms-1"></i>
                    </span>
                    <span v-if="!item.user_rating?.testimonial" class="">
                      Sem depoimento
                    </span>
                  </td>
                  <td class="text-nowrap" data-bs-toggle="tooltip" data-bs-placement="bottom"
                    :title="item?.user_rating?.description" aria-describedby="tooltip302047"
                    :ref="`tooltip_user_${index}`">
                    <span v-if="item.user_rating?.rating">
                      <i class="fa-solid fa-video"></i>
                      <span v-if="item.user_rating?.rating > 3" class="fw-bold">
                        {{ item.user_rating?.rating }}.0
                      </span>
                      <span v-if="item.user_rating?.rating <= 3" class="fw-bold text-danger">
                        {{ item.user_rating?.rating }}.0
                      </span>
                      <i v-if="item.user_rating?.rating > 3" class="fa-solid fa-star text-warning"></i>
                      <i v-if="item.user_rating?.rating <= 3" class="fa-solid fa-star text-danger"></i>
                      <i v-if="item.user_rating?.description" class="fa-solid fa-lg fa-circle-info text-info ms-1"></i>
                    </span>
                    <span v-if="!item.user_rating?.rating" class="">
                      Sem avaliação
                    </span>
                  </td>
                  <td>
                    <div class="avatar avatar-xs align-middle me-2">
                      <img v-if="item.psychologist.profile_image"
                        :src="`${$baseImageUrl}${item.psychologist.profile_image}`" alt="..."
                        class="avatar-img rounded-circle" />
                      <img v-if="!item.psychologist.profile_image" src="@/assets/img/avatar_placeholder.svg" alt="..."
                        class="avatar-img rounded-circle" />
                    </div>
                    <span>
                      ({{ item.psychologist.id }}) <span class="fw-bold">{{ item.psychologist.name }}</span>
                      <span v-if="item.psychologist.deleted_at" class="text-danger fw-bold">
                        (Excluído)
                      </span>
                    </span>
                  </td>
                  <td class="text-nowrap" data-bs-toggle="tooltip" data-bs-placement="bottom"
                    :title="item?.psi_rating?.description" aria-describedby="tooltip302047"
                    :ref="`tooltip_user_${index}`">
                    <span v-if="item.psi_rating?.rating">
                      <i class="fa-solid fa-video"></i>
                      <span v-if="item.psi_rating?.rating > 3" class="fw-bold">
                        {{ item.psi_rating?.rating }}.0
                      </span>
                      <span v-if="item.psi_rating?.rating <= 3" class="text-danger fw-bold">
                        {{ item.psi_rating?.rating }}.0
                      </span>
                      <i v-if="item.psi_rating?.rating > 3" class="fa-solid fa-star text-warning"></i>
                      <i v-if="item.psi_rating?.rating <= 3" class="fa-solid fa-star text-danger"></i>
                      <i v-if="item.psi_rating?.description" class="fa-solid fa-lg fa-circle-info text-info ms-1"></i>
                    </span>
                    <span v-if="!item.psi_rating?.rating" class="">
                      Sem avaliação
                    </span>
                  </td>
                  <!-- <td
                    v-if="item.psi_rating"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    :title="item?.psi_rating?.description"
                    :ref="`tooltip_psi_${index}`"
                  >
                    {{ item.psi_rating.rating }}
                    <RatingStars :maxRating="5" :rating="item.psi_rating?.rating || 0" />
                    <i v-if="item.psi_rating?.description" class="fa-solid fa-lg fa-envelope-open-text ms-3"></i>
                  </td>
                  <td v-else class="text-muted">Sem avaliação</td> -->
                  <td class="text-end">
                    <button @click="handleDetail($event, item.id)" class="btn btn-sm btn-white lift" target="_blank">
                      <span class="fe fe-arrow-up-right"></span>
                    </button>
                  </td>
                  <!-- </tr> -->
                </router-link>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row" v-if="!loadingItems">
        <div class="col-12 text-end">
          <span class="text-muted">mostrando <b>{{ countItemsShowing }}</b> de
            <b>{{ countItemsTotal }}</b> resultado(s)</span>
        </div>
      </div>
      <div class="row justify-content-center" v-if="!loadingItems && moreItemsToLoad">
        <div class="col-2">
          <FormButton label="Carregar mais" :loading="loadingMoreItems" @onClick="loadMore" />
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import RatingsController from "./RatingsController";
export default RatingsController;
</script>
