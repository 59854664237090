import ListMixinController from '../../../mixins/ListMixinController'
export default {
  watch: {
    "$route.params"(value) {
      this.setupSettings()
    }
  },
  data() {
    return {
      text: '',
      loadingItems: false,
      submitting: false,
      errorMessage: '',
      settingsInfo: {
        key: '',
        viewPath: '',
        title: '',
      }
    };
  },
  mounted() {
    this.setupSettings()
  },
  validations: {
    text: '',
  },
  methods: {
    setupSettings() {
      this.loadingItems = true
      let k = this.$route.params.key
      console.log('queryyyyyyy', k)
      switch (k) {
        case 'termos-usuario':
          this.$set(this, 'settingsInfo', {
            key: 'terms-of-use',
            // path: this.$paths.contracts.list,
            title: 'Termos de Uso Usuário'
          })
          break;
        case 'termos-psicologos':
          this.$set(this, 'settingsInfo', {
            key: 'terms-of-use-psi',
            // viewPath: this.$paths.settings.support.view,
            title: 'Termos de Uso Psicólogos'
          })
          break;
        case 'politica-privacidade':
          this.$set(this, 'settingsInfo', {
            key: 'privacy-policy',
            // viewPath: this.$paths.settings.about.view,
            title: 'Política de Privacidade'
          })
          break;
        default: this.$router.push({ path: this.$paths.not_found })
      }

      this.getData()
    },

    async getData() {
      try {
        const res = await this.$userService.settings(this.settingsInfo.key)
        this.$set(this, 'text', res.data.terms_of_use || res.data)
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingItems = false
      }
    },
    async submit() {
      try {
        this.submitting = true
        this.errorMessage = ''
        const res = await this.$userService.updateSettings(this.settingsInfo.key, this.text)
        this.$eventBus.$emit('showToast', { title: 'Informações salvas', msg: `Informações alteradas com sucesso` })
      } catch (e) {
        this.errorMessage = e.message
      } finally {
        this.submitting = false
      }
    },
    view() {
      let routeData = this.$router.resolve({ path: this.settingsInfo.viewPath })
      window.open(routeData.href, '_blank');
    }

  }
};