<template>
  <div id="appointments">
    <div class="card">
      <SearchBar :allowSearch="true" @onSearch="search">
        <!-- v-if="totalItemsRegistered > 0" -->
        <template v-slot:filterSlot v-if="showStatusFilter">
          <AppointmentFiltersContainer
            :currentTab="propFilters.type"
            @filtersChanged="filtersChanged($event)"
          />
        </template>
      </SearchBar>
      <LoadingContainer v-if="loadingItems" />
      <div v-else>
        <EmptyState
          v-if="!items.length"
          :totalItemsRegistered="totalItemsRegistered"
        >
          <template v-slot:noItemsRegistered>
            <span class="fa-3x far fa-calendar-check text-black-50 mb-2"></span>
            <p>Nenhuma sessão</p>
          </template>
        </EmptyState>
        <template v-else>
          <div class="table-responsive">
            <table
              ref="myTable"
              class="table table-sm table-nowrap card-table table-hover cursor-pointer"
            >
              <TableHead :columns="columns" v-on:sort="sort" />
              <tbody class="list">
                <AppointmentTableItem
                  v-for="(appointment, index) in items"
                  :item="appointment"
                  :index="index"
                  :key="index"
                />
              </tbody>
            </table>
          </div>
        </template>
      </div>
    </div>
    <div class="row" v-if="!loadingItems">
      <div class="col-12 text-end">
        <span class="text-muted"
          >mostrando <b>{{ countItemsShowing }}</b> de
          <b>{{ countItemsTotal }}</b> resultado(s)</span
        >
      </div>
    </div>
    <div
      class="row justify-content-center"
      v-if="!loadingItems && moreItemsToLoad"
    >
      <div class="col-2">
        <FormButton
          label="Carregar mais"
          :loading="loadingMoreItems"
          @onClick="loadMore"
        />
      </div>
    </div>
  </div>
</template>
<script>
import AppointmentListController from "./AppointmentListController";
export default AppointmentListController;
</script>
