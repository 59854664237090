import { email, required, minLength, requiredIf } from 'vuelidate/lib/validators';
export default {
  name: 'AddTasks',
  mounted() {
    if (this.id) {
      this.getData()
    }
    this.getCategories()
  },
  data() {
    return {
      id: this.$route.params.id,
      existingImage: '',
      TITLE_MAX_LENGTH: 120,
      CONTENT_MAX_LENGTH: 2400,
      errorMessage: '',
      submitting: false,
      form: {
        category_id: '',
        type: 'text',
        title: '',
        text: '',
        image: ''
      },
      categories: [],
      types: [{
        id: 'text',
        title: 'Artigo'
      }]
    };
  },
  validations() {
    return {
      form: {

        image: !this.id ? {
          required,
        } : '',
        category_id: {
          required,
        },
        type: {
          required,
        },
        title: {
          required,
          minLength: minLength(10)
        },
        text: {
          required,
          minLength: minLength(10)
        },
      }
    }
  },
  methods: {
    async submit() {
      try {
        this.submitting = true
        this.errorMessage = ''
        const values = { ...this.form }
        if (values.image) {
          values.image = values.image.file
        }
        let res
        if (!this.id) {
          res = await this.$postsService.create(values)
          this.$eventBus.$emit('showToast', { title: 'Post criado', msg: `Post criado com sucesso` })
          this.$router.push({ path: `${this.$paths.posts.list}` })
        } else {
          res = await this.$postsService.update(this.id, values)
          this.$eventBus.$emit('showToast', { title: 'Post alterada', msg: `Post alterada com sucesso` })
          this.$router.back()
        }
      } catch (e) {
        this.errorMessage = e.message
      } finally {
        this.submitting = false
      }
    },
    async getCategories() {
      try {
        const res = await this.$postsService.listCategories()
        this.$set(this, 'categories', res.data)
      } catch (e) {
        console.log(e)
      } finally {
      }
    },
    cropEnd(image) {
      this.$set(this.form, 'image', image)
    },
    editImageClicked() {
      document.getElementById('content-add-message-input').click()
    },
    removeImage() {
      this.$set(this.form, 'image', '')
    },
    async getData() {
      try {
        const res = await this.$postsService.show(this.id)
        let f = {
          title: res.data.title,
          text: res.data.text,
          category_id: res.data.category_id,
          type: res.data.type,
        }
        this.$set(this, 'existingImage', res.data.image_url)
        this.$set(this, 'form', f)
      } catch (e) {
        console.log(e)
      } finally {
      }
    },
  }
};