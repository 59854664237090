import { email, required, sameAs } from 'vuelidate/lib/validators';
export default {
  name: 'AddAdmins',
  mounted() {
  },
  data() {
    return {
      id: this.$route.params.id,
      type: this.$route.params.type,
      errorMessage: '',
      submitting: false,
      filters: {
        page: 1,
        search: ''
      },
      form: {
        old_password: '',
        password: '',
        passwordConfirmation: ''
      },
    };
  },
  validations: {
    form: {
      old_password: {
        required,
      },
      password: {
        required,
      },
      passwordConfirmation: { required, sameAs: sameAs('password') },
    }
  },
  methods: {
    async submit() {
      try {
        this.submitting = true
        this.errorMessage = ''
        const f = { ...this.form }
        delete (f.passwordConfirmation)
        const res = await this.$userService.update(this.id, f)
        this.$eventBus.$emit('showToast', { title: 'Senha alterada', msg: `Senha alterada com sucesso` })
        this.$router.back()
      } catch (e) {
        this.errorMessage = e.message
      } finally {
        this.submitting = false
      }
    }

  }
};