import ListMixinController from "@/mixins/ListMixinController";
import AppointmentFiltersContainer from "../AppointmentList/AppointmentFiltersContainer.vue";

export default {
  mixins: [ListMixinController],
  props: {
    propFilters: {
      type: Object,
      default: {},
    },
    showStatusFilter: {
      type: Boolean,
      default: true,
    },
    // NOTE: Recebo o dateRange do componente pai. Tentei receber ela dentro do próprio propFilters, mas por algum motivo não funcionou, quando eu mudava ela, nada acontecia.
    dateRange: {
      default: null,
    },
  },
  mounted() {
    this.loadingItems = true;
    this.filters = {
      ...this.filters,
      ...this.propFilters,
    };

    // NOTE Se não tiver filtro de data eu já chamo a função aqui, se não chamo só no watch do dateRange pra não ficar chamando a API desnecessariamenmte
    setTimeout(() => {
      if (!this.dateRange) {
        this.getData();
      }
    }, 500);
  },
  watch: {
    totalItemsRegistered(value) {
      this.$emit("totalItemsChanged", { total: value });
    },
    // NOTE: Função que fica ouvindo a variavel dateRange pra fazer a lógica do filtro
    dateRange(value) {
      this.filters = {
        ...this.filters,
        date_start: value[0] ? this.$moment(value[0]).format("YYYY-MM-DD") : "",
        date_end: value[1] ? this.$moment(value[1]).format("YYYY-MM-DD") : "",
      };

      // NOTE: Tive que deletar esses parâmetros da rota antes porque por algum motivo dava dando ruim, dizendo que era duplicidade de rota quando eu dava o router.replace
      delete this.$route.query.date_start;
      delete this.$route.query.date_end;

      let query = {
        ...this.$route.query,
        date_start: this.filters.date_start,
        date_end: this.filters.date_end,
      };

      // NOTE: Seta os filtros na url
      this.$router.replace({ query });

      this.getData();
    },
  },
  data() {
    return {
      hasDateFilter: this.dateRange,
      columns: [
        {
          name: "ID",
          sortName: null,
          order: null,
        },
        {
          name: "Psicólogo",
          sortName: null,
          order: null,
        },
        {
          name: "Paciente",
          sortName: null,
          order: null,
        },
        {
          name: "Criado em",
          sortName: null,
          order: null,
        },
        {
          name: "Horário",
          sortName: "date",
          order: "DESC",
        },
        {
          name: "Valor",
          sortName: null,
          order: null,
        },
        {
          name: "Pagamento",
          sortName: null,
          order: null,
        },
        {
          name: "Recorrência",
          sortName: null,
          order: null,
        },
        {
          name: "Status",
          sortName: null,
          order: null,
        },
        // {
        //   name: "Atalho",
        //   sortName: null,
        //   order: null,
        // },
      ],
    };
  },
  methods: {
    prepareResponse(data) {
      return data.map((elem) => ({
        ...elem,
        paymentStatus:
          this.$appointmentsService.getAppointmentPaymentStatus(elem),
        statusObject: this.$appointmentsService.getAppointmentStatus(
          elem.status
        ),
      }));
    },
    fetchPromise() {
      let f = {
        ...this.filters,
        orderBy: this.columns.filter(
          (r) => r.order != null && r.sortName != null
        ),
      };
      return this.$appointmentsService.list(f);
    },
    filtersChanged(event) {
      this.loadingItems = true;
      this.$set(this, "filters", {
        ...this.filters,
        ...event.defaultFilters,
      });

      this.getData(false, true);
    },
    handleDetail(event, id) {
      event.preventDefault();
      const path = this.$router.resolve({
        path: `${this.$paths.appointments.detail}/${id}`,
      });
      window.open(path.href, "_blank");
    },
  },
  components: {
    AppointmentFiltersContainer,
  },
};
