import VideoService from '@/services/VideoService';
import moment from 'moment';
import { email, required, sameAs, minLength, helpers, minValue, maxValue, withMessage } from 'vuelidate/lib/validators';

export default {
  name: 'AddCoupon',
  mounted() { },
  data() {
    return {
      errorMessage: '',
      submitting: false,
      form: {
        description: '',
        thumbnail: null,
        target: VideoService.APP_TARGET.USER,
        video: null,
      },
      targetSelectOptions: [
        {
          id: VideoService.APP_TARGET.USER,
          title: 'Pacientes'
        },
        {
          id: VideoService.APP_TARGET.PSI,
          title: 'Psicólogos'
        },
      ]
    };
  },
  computed: {
  },
  validations() {
    return {
      form: {
        description: {
          required,
          minLength: minLength(6),
        },
        thumbnail: {
          required,
        },
        video: {
          required,
        },
        target: {
          required,
        },
      }
    }
  },
  methods: {
    selectChanged(event) {
      this.form.target = event
    },
    async cropEnd($event) {
      this.form.thumbnail = $event.file
    },
    async onVideoUploaded($event) {
      this.form.video = $event.file
    },
    async submit() {
      // console.log(this.form)
      // return
      try {
        this.submitting = true
        this.errorMessage = ''
        const res = await this.$videoService.create(this.form)
        this.$eventBus.$emit('showToast', { title: 'Vídeo criado', msg: `Vídeo adicionado com sucesso` })
        this.$router.push({ path: `${this.$paths.videos.list}` })
      } catch (e) {
        this.errorMessage = e.message
      } finally {
        this.submitting = false
      }
    },
  }
};