import NetworkService from '@/services/NetworkService.js'

export default class PostsService {
    constructor() {
        this.network = new NetworkService()
    }
    create = (params) => {
        return this.network.postMultipart(this.network.endpoints.posts, params)
    }
    list = (params) => {
        return this.network.get(this.network.endpoints.posts, params)
    }
    show = (id) => {
        return this.network.get(`${this.network.endpoints.posts}/${id}`)
    }
    update = (id, params) => {
        return this.network.postMultipart(`${this.network.endpoints.posts}/${id}/edit`, params)
    }
    listCategories = () => {
        return this.network.get(`${this.network.endpoints.postCategories}`)
    }
}