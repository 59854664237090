import "cropperjs/dist/cropper.css";
import { defineComponent } from 'vue';
import VueCropper from "vue-cropperjs";

export default defineComponent({
    props: {
        accept: {
            type: String,
            default: '.mov,.mp4'
        },
        customFileInputWidth: {
            type: Number,
            default: 100,
        },
        inputId: {
            type: String,
            default: `def-${new Date().getTime()}`,
        },
        hiddenInput: {
            type: Boolean,
            default: false,
        },
        // max file size in MB
        maxFileSize: {
            type: Number,
            default: 100,
        }

    },
    computed: {
        fileName() {
            return this.originalFile ? `${this.originalFile.name}` : ``
        },
        input_name() {
            return `${this.inputId}-name`
        },
    },
    mounted() {
        this.$eventBus.$on("clearVideoUploader", () => {
            this.originalFile = null
        });
    },
    data() {
        return {
            originalFile: null,
            videoUrl: null
        }
    },
    components: {
        VueCropper,
    },
    methods: {
        isFileSizeValid(value, maxSizeInMb = 1.5) {
            if (!value) { return true }
            return value.size / 1024 <= (maxSizeInMb * 1024)
        },
        inputChanged(e) {
            this.originalFile = e.target.files[0];
            this.videoUrl = window.URL.createObjectURL(this.originalFile);
            console.log('this.videoUrl', this.videoUrl)
            let result = { file: this.originalFile }
            this.$emit('onUploaded', result)
        },
    }
})