<template>
  <div class="row">
    <div class="col-12">
      <EmptyState v-if="!item.payouts" :msg="' '" :title="'Nenhum repasse'" />
      <table class="table table-borderless table-hover table-sm" v-else>
        <TableHead :columns="columns" />
        <tbody>
          <tr v-for="(item, index) in item.payouts" v-bind:key="'hr-' + index">

            <td>
              {{ item.formatted_payout?.date }}
            </td>
            <td>
              <ul class="custom-ul">
                <li v-for="(transaction) in item.appointments">

                  <a :href="`${$paths.appointments.detail}/${transaction.appointment_id}`" target="_blank"> {{ truncateName(transaction.appointment.user.name) }} -


                    {{
                      $moment(transaction.appointment.date).format(
                        "DD/MM/YYYY[ - ]HH:mm"
                      )
                    }}
                    <i class="fa-solid fa-arrow-up-right-from-square"></i>
                  </a>

                  
                </li>
              </ul>

              <!-- <div class="dropdown">
                <button type="button" class="btn btn-primary btn-xs dropdown-toggle" data-toggle="dropdown">
              <i class="fa-solid fa-info"></i> 
                </button>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="#">Link 1</a>
                  <a class="dropdown-item" href="#">Link 2</a>
                  <a class="dropdown-item" href="#">Link 3</a>
                </div>
              </div> -->
            </td>
            <td>
              <strong>{{ item.formatted_payout?.amount }}</strong>
              
            </td>
            <td class="text-end">


              <span :class="item.text_class">
                <strong>{{ item.formatted_payout?.statusObject?.title }}</strong>
              
            </span>

              <a :href="`${item.stripe_link}`" target="_blank"> 
                <i class="fa-brands fa-cc-stripe fa-xl text-dark" style="margin-left: 5px"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<style>
.custom-ul {
    list-style-type: none; /* Remove bullet points */
    padding: 0; /* Optional: Remove default padding */
    margin-left:0;
    font-size: smaller;
}
</style>
<script>
export default {
  name: "PsiDetailPayouts",
  props: {
    item: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      columns: [
        {
          name: 'Data de pagamento',
          sortName: null,
          order: null
        },
        {
          name: 'Sessões',
          sortName: null,
          order: null
        },
        {
          name: 'Valor',
          sortName: null,
          order: null
        },
        {
          name: 'Status',
          sortName: null,
          order: null
        }
      ]
    }
  },
  created() {
    this.addStripeLinks();
  },

  methods: {
    truncateName(name, maxLength = 8) {
      return name.length > maxLength ? name.substring(0, maxLength) + '...' : name;
    },
    addStripeLinks() {
      if (this.item && this.item.payouts && this.item.stripe_id) {
        this.item.payouts.forEach(payout => {

          const url_mid = process.env.VUE_APP_ENV == 'prod' ? "payouts" : "test/payouts"

          payout.stripe_link = `https://dashboard.stripe.com/${this.item.stripe_id}/${url_mid}/${payout.stripe_payout_id}`;
          payout.text_class = payout.status == 'paid' ? 'text-dark' : 'text-danger';
          // console.log(payout.status)
        });
      }
    }
  }
  
}
</script>