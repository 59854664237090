import { render, staticRenderFns } from "./EditAdmins.vue?vue&type=template&id=1f55fb71&scoped=true"
import script from "./EditAdmins.vue?vue&type=script&lang=js"
export * from "./EditAdmins.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f55fb71",
  null
  
)

export default component.exports