import {
  changeActive,
  changeAgenda,
  changeApprove,
  changeEmail,
  givePremium,
  removePremium,
} from "@/views/Users/UserDetail/userFunctions";
import { Modal } from "bootstrap";
import { email, required } from "vuelidate/lib/validators";
import {
  PsiDetailAppointments,
  PsiDetailBankInfo,
  PsiDetailCoupons,
  PsiDetailPayouts,
  PsiDetailProfile,
  PsiDetailRatings,
  PsiDetailRegisterInfo,
  PsiDetailSchedules,
  PsiDetailSummary,
} from "./PsiDetailComponents";

export default {
  mounted() {
    this.changeEmailModal = new Modal(
      document.getElementById("changeEmailModal"),
      {
        backdrop: "static",
        keyboard: false,
      }
    );
    this.loadingItems = true;
    this.getData();
  },
  data() {
    return {
      changeEmailModal: null,
      item: {},
      id: this.$route.params.id,
      loadingItems: false,
      submitting: false,
      errorMessage: "",
      form: {
        email: "",
      },
    };
  },
  components: {
    PsiDetailRegisterInfo,
    PsiDetailProfile,
    PsiDetailSchedules,
    PsiDetailBankInfo,
    PsiDetailAppointments,
    PsiDetailPayouts,
    PsiDetailRatings,
    PsiDetailCoupons,
    PsiDetailSummary,
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    async getData() {
      try {
        const res = await this.$userService.show(this.id);
        let data = this.$userService.preparePsi(res.data);
        const couponsRes = await this.$couponsService.list({
          psychologist_id: this.id,
        });
        data.coupons = couponsRes.data.map((r) =>
          this.$couponsService.prepareCoupon(r)
        );
        this.$set(this, "item", data);
        this.$set(this.form, "email", data.email);
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingItems = false;
      }
    },
    changeEmailClicked() {
      this.changeEmailModal.toggle();
    },
    handleChangeEmail() {
      this.submitting = true;
      changeEmail({ id: this.item.id, email: this.form.email }, () => {
        this.$eventBus.$emit("showToast", {
          title: "Email alterado",
          msg: `O email foi alterado com sucesso`,
        });
        this.changeEmailModal.toggle();
        this.getData();
        this.submitting = false;
      });
    },
    handleChangeActive() {
      changeActive(this.item, () => this.getData());
    },
    handleChangeApprove() {
      changeApprove(this.item, () => {
        this.$eventBus.$emit("showToast", {
          title: !this.item.approved
            ? "Usuário aprovado"
            : "Usuário Desaprovado",
          msg: !this.item.approved
            ? `O Usuário foi aprovado com sucesso`
            : `O Usuário foi desaprovado`,
        });
        this.getData();
      });
    },
    handleGivePremium() {
      givePremium(this.item, () => {
        this.$eventBus.$emit("showToast", {
          title: "Psi promovido para premium",
          msg: `O Psi é agora é um assinante`,
        });
        this.getData();
      });
    },
    handleRemovePremium() {
      removePremium(this.item, () => {
        this.$eventBus.$emit("showToast", {
          title: "Premium removido do psi",
          msg: `O Psi é agora é um usuário free`,
        });
        this.getData();
      });
    },
    handleChangeAgenda() {
      changeAgenda(this.item, () => {
        this.getData();
      });
    },
    handleCouponDetail(id) {
      this.$router.push({ path: `${this.$paths.coupons.detail}/${id}` });
    },
    async changeCallProvider(value) {
      try {
        let params = { call_provider: value };
        await this.$userService.update(this.item?.id, params);
        this.getData();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
