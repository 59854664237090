import ListMixinController from "@/mixins/ListMixinController";

export default {
  mixins: [ListMixinController],
  mounted() {
    this.loadingItems = true;
    this.getData();
  },
  computed: {
    userSession() {
      return this.$store.state.userSession;
    },
  },
  data() {
    return {
      columns: [
        {
          name: "Usuário",
          sortName: null,
          order: null,
        },
        {
          name: "Titulo",
          sortName: null,
          order: null,
        },
        {
          name: "Categoria",
          sortName: null,
          order: null,
        },
        {
          name: "Status",
          sortName: null,
          order: null,
        },
        {
          name: "Criado em",
          sortName: null,
          order: null,
        },
      ],
    };
  },
  methods: {
    prepareResponse(data) {
      return data.map((elem) => ({
        ...elem,
        // statusObject: this.$tasksService.getTaskStatus(elem.status),
        // pillarObject: this.$missionsService.getMissionPillar(elem.pillar),
      }));
    },
    fetchPromise() {
      return this.$postsService.list({
        ...this.filters,
        orderBy: this.columns.filter(
          (r) => r.order != null && r.sortName != null
        ),
      });
    },
  },
};
