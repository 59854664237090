export default {
  mounted() {
    this.loadingItems = true
    this.getData()
  },
  created() {

  },
  computed: {
    userSession() {
      return this.$store.state.userSession;
    },
  },
  data() {
    return {
      loadingDelete: false,
      loadingApprove: false,
      item: {},
      id: this.$route.params.id,
      loadingItems: false,
    };
  },
  methods: {
    async getData() {
      try {
        const res = await this.$postsService.show(this.id)
        let data = res.data
        data.formatted_type = data.type == 'video' ? 'Video' : 'Imagem'
        data.formatted_date = this.$moment(data.created_at).format("DD/MM/YYYY - HH:mm")
        this.$set(this, 'item', data)
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingItems = false
      }
    },
    async approve() {
      try {
        await this.$postsService.update(this.id, { active: 1 })
        this.$set(this.item, 'active', true)
      } catch (e) {
        console.log(e)
      } finally {
      }
    },
    deletePost() {
      try {
        this.$eventBus.$emit("showConfirmationAlert", {
          message: "Deseja excluir esse item?",
          confirmCallback: async () => {
            await this.$postsService.update(this.id, { active: 0 })
            this.$set(this.item, 'active', false)
          },
        });
      } catch (e) {
        console.log(e)
      } finally {
      }
    },
  }
}