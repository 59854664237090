<template>
  <div id="appointment_events">
    <div class="row">
      <div class="col">
        <div v-for="(event, index) in item.events" v-bind:key="'evt-' + index" class="comment">
          <div class="comment-body d-block">
            <div class="row">
              <div class="col">
                <h5 class="comment-title fw-bold d-block">
                  {{ event.code }}
                </h5>
              </div>
              <div class="col-auto">
                <time class="comment-time">
                  {{ $moment(event.created_at).format('DD/MM/YYYY[ - ]HH:mm') }}
                </time>
              </div>
            </div>
            <p class="comment-text mb-1">
              {{ event.event }}
            </p>

            <p v-if="event.device_details" class="comment-time mb-1" style="text-transform: uppercase;">

              {{ event.device_details.model }} -
              {{ event.device_details.os }}
              {{ event.device_details.os_version }} -
              {{ event.device_details.app }}
              {{ event.device_details.appVersion }}

            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EventsComponent',
  props: {
    item: Object,
    default: {}
  }
}
</script>
