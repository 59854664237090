<template>
  <div class="col-auto me-n3">
    <div class="dropdown">
      <button id="filter-btn" class="btn btn-sm btn-white" type="button" data-bs-toggle="dropdown"
        data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">
        <i class="fe fe-sliders me-1"></i>
        Filtro
        <span v-if="defaultFilters.status.length" class="badge badge-sm bg-primary">{{ defaultFilters.status.length
        }}</span>
      </button>

      <div class="dropdown-menu dropdown-menu-end dropdown-menu-card">
        <div class="card-header">
          <h4 class="card-header-title fw-bold">Filtrar por</h4>
          <a class="text-primary cursor-pointer" @click.prevent="clearFilters()">
            <strong>
              <small>Limpar filtro</small>
            </strong>
          </a>
        </div>
        <div class="card-body">
          <div class="list-group list-group-flush mt-n4 mb-4">
            <div class="list-group-item">
              <div class="row">
                <div class="col mb-3">
                  <strong>
                    <small>Status</small>
                  </strong>
                </div>
                <div class="col-auto">
                  <!-- <div
                    class="choices"
                    data-type="select-one"
                    tabindex="0"
                    role="listbox"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div class="form-select-sm">
                      <select
                        class="form-select form-select-sm form-control"
                        data-choice="active"
                        v-model="selectedStatus"
                      >
                        <option
                          v-for="(
                            item, index
                          ) in $appointmentsService.statusList"
                          v-bind:key="'option_' + index"
                          :value="item.id"
                        >
                          {{ item.title }}
                        </option>
                      </select>
                    </div>
                  </div> -->
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-check" v-for="(item, index) in statusList" v-bind:key="'option_' + index">
                    <input @change="changeFilters()" class="form-check-input" type="checkbox" :value="item.id"
                      v-model="item.checked" :id="`${currentTab}_${item.id}_${index}`" />
                    <label class="form-check-label" :for="`${currentTab}_${item.id}_${index}`">
                      {{ item.title }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button class="btn w-100 btn-primary" @click="applyFilters()">
            Concluir
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    // NOTE: Criei essa props porque tava dando conflito nos id do checkbox, então tive que usar a tab para diferenciar
    currentTab: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      statusList: [],
      defaultFilters: {
        status: [],
      },
    };
  },
  mounted() {
    this.statusList = this.$appointmentsService.statusList.map((elem) => ({
      ...elem,
      checked: this.defaultFilters.status.some((status) => status == elem.id),
    }));
  },
  methods: {
    // NOTE: Alterei algumas coisas nas lógicas dessas funções para montar os checkbox
    clearFilters() {
      this.statusList = this.$appointmentsService.statusList.map((elem) => ({
        ...elem,
        checked: false,
      }));
      this.defaultFilters.status = [];
      this.emitEvent();
    },
    changeFilters() {
      let selectedStatus = this.statusList
        .filter((elem) => elem.checked)
        .map((elem) => elem.id);

      if (selectedStatus.length) {
        this.defaultFilters.status = selectedStatus;
      } else {
        this.defaultFilters.status = [];
      }

      this.emitEvent(true);
    },
    emitEvent(setQuery) {
      // console.log(setQuery)
      // if (setQuery) {
      this.$emit("filtersChanged", {
        defaultFilters: this.defaultFilters,
        setQuery,
      });
      // }
    },
    applyFilters() {
      document.getElementById("filter-btn").click();
    },
  },
};
</script>
