import NetworkService from "@/services/NetworkService";
import moment from "moment";
import PayoutService from "./PayoutService";

export default class PartnerService {
  constructor() {
    this.network = new NetworkService();
    this.payoutService = new PayoutService();
  }
  SUBSCRIPTION_STATUS = {
    trialing: "trialing",
    active: "active",
  };
  list = (filters) => {
    return this.network.get(this.network.endpoints.partnerships, filters);
  };
  create = (params) => {
    return this.network.post(this.network.endpoints.partnerships, params);
  };
  show = async (id) => {
    try {
      const partner = await this.network.get(
        `${this.network.endpoints.partnerships}/${id}`
      );
      const appointments = await this.network.get(
        `${this.network.endpoints.appointments}`,
        {
          partnership_id: id,
        }
      );
      const partnerPatients = await this.network.get(
        `${this.network.endpoints.partnerPatients}`,
        { partnership_id: id }
      );

      return {
        status: true,
        data: {
          ...partner.data,
          appointments: appointments.data,
          patients: partnerPatients.data,
        },
      };
    } catch (error) {
      console.log(error);
      return error;
    }
  };
  delete = (id) => {
    return this.network.delete(`${this.network.endpoints.partnerships}/${id}`);
  };
  preparePartner(item) {
    try {
      item.formatted_created_at = moment(item.created_at).format("DD/MM/YYYY");
      item.formatted_status = item.status ? "Ativo" : "Inativo";

      console.log(item);
    } catch (e) {
      console.log(e);
    } finally {
      return item;
    }
  }
}
