<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="card mb-0">
          <div class="card-body text-start">
            <p class="text-muted mb-2">
              Avaliações
            </p>
            <span class="h2 fw-bold mb-0">
              <i class="fa-solid fa-user-group"></i>
              {{ item.latest_ratings?.length }}
            </span>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card mb-0">
          <div class="card-body text-start">
            <p class="text-muted mb-2">
              Depoimentos
            </p>
            <span class="h2 fw-bold mb-0">
              <i class="fa-solid fa-feather"></i>
              Em breve
            </span>
          </div>
        </div>
      </div>
    </div>

    <hr class="my-4">
    <h2 class="fw-bold mb-4">Avaliações</h2>
    <div class="row">
      <div class="col">
        <div v-for="(latest_ratings, index) in item.latest_ratings" v-bind:key="'hr-' + index" class="card">
          <div class="card-body">
            <div class="mb-3">
              <div class="row align-items-center">
                <div class="col">
                  <div class="avatar avatar-xs align-middle me-2">
                    <img src="@/assets/img/avatar_placeholder.svg" alt="..." class="avatar-img rounded-circle">
                  </div>
                  <span class="mb-1 fw-bold">
                    {{ latest_ratings.user.name }}
                  </span>
                </div>
                <div class="col-auto">
                  <span v-if="latest_ratings?.psi_rating">
                    <span v-if="latest_ratings?.psi_rating > 3" class="fw-bold">
                      {{ latest_ratings?.psi_rating }}.0
                    </span>
                    <span v-if="latest_ratings?.psi_rating <= 3" class="fw-bold text-danger">
                      {{ latest_ratings?.psi_rating }}.0
                    </span>
                    <i v-if="latest_ratings?.psi_rating > 3" class="fa-solid fa-star text-warning"></i>
                    <i v-if="latest_ratings?.psi_rating <= 3" class="fa-solid fa-star text-danger"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="comment-body d-block my-4">
              <p v-if="latest_ratings.testimonial" class="mb-0">
                {{ latest_ratings.testimonial }}
              </p>
              <p v-else class="comment-text text-muted">
                Sem descrição
              </p>
            </div>
            <div class="mb-0">
              <div class="row">
                <div class="col">
                  <p class="card-text small text-body-secondary">
                    <i class="fa-regular fa-calendar"></i>
                    <time>
                      {{ $moment(latest_ratings.created_at).format("DD/MM/YY") }}
                    </time>
                    <i class="fa-regular fa-clock ms-"></i>
                    <time>
                      {{ $moment(latest_ratings.created_at).format("HH:mm") }}
                    </time>
                  </p>
                </div>
                <div class="col-auto">
                  <button class="btn btn-sm btn-white text-muted me-2">
                    <i class="fa-regular fa-trash-can me-1"></i>
                    Remover
                  </button>
                  <router-link :to="`${$paths.appointments.detail}/${latest_ratings.appointment_id}`" tag="a"
                    target="_blank" class="btn btn-sm btn-white" role="button">
                    <i class="fa-regular fa-calendar me-1"></i>
                    Ver sessão
                  </router-link>
                  <!-- <button class="btn btn-sm btn-white">
                                </button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="item.latest_ratings?.length <= 0" class="py-6">
        <p class="text-muted text-center">
          Nenhuma avaliação
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PsiDetailRatings",
  props: {
    item: {
      type: Object,
      default: () => { },
    },
  },
}
</script>