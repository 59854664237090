import DetailMixinController from '@/mixins/DetailMixinController';
export default {
  name: 'CouponDetail',
  mixins: [DetailMixinController],
  mounted() {
    this.hasDelete = true
  },
  methods: {
    fetchPromise() {
      return this.$couponsService.show(this.id)
    },
    prepareResponse(data) {
      return this.$couponsService.prepareCoupon(data)
    },
    deletePromise() {
      return this.$couponsService.delete(this.id)
    }
  }
}