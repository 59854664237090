<template>
  <div id="add-coupon">
    <Navbar current="Adicionar código" :link="$paths.coupons.list" :linkName="'Códigos personalizados'" />
    <Sidebar />
    <Container>
      <Header title="Adicionar Código" />
      <div class="card">
        <div class="card-body">
          <h3>Informações</h3>
          <div class="row">
            <div class="col-12 col-md-12">
              <TextInput label="Código" :required="true" :model="$v.form.code" :maxLength="20" type="text"
                :inputStyle="'text-transform:uppercase'"
                :errorMsg="' Informe um código com no mínimo 6 caractéres somente com letras e numeros e sem espaços'"
                placeholder="ANDRE3000" />
            </div>
            <div class="col-12 col-md-12">
              <TextInput label="Desconto(%)" :model="$v.form.discount" :maxLength="3" type="text" placeholder="10%"
                :errorMsg="'Informe a porcentagem de desconto entre 5% e 90%'" :mask="'##'" />
              <div class="bg-light rounded p-3 mb-4" style="margin-top: -10px;">
                <small>Ao aplicar o código personalizado à consulta, o valor mínimo a ser pago pelo paciente será de {{appointmentThreshold}}
                  reais, mesmo que o
                  total com desconto resulte em um valor menor.</small>
              </div>
            </div>

            <div class="col-12 col-md-12">
              <div class="card">
                <div class="card-body ">
                  <div class="row">
                    <div class="col-auto" :class="{ 'opacity-50': !$v.form.hasExpirationDate.$model }">
                      <span class="fas fa-calendar fa-medium-size text-primary"></span>
                    </div>
                    <div class="col ps-0" :class="{ 'opacity-50': !$v.form.hasExpirationDate.$model }">
                      <h3 class="mb-0">{{ 'Data de validade do código' }}</h3>
                      <span>{{ expirationDateMessage }}</span>
                    </div>
                    <div class="col-auto d-flex align-items-center">
                      <div class="form-check form-switch">
                        <input class="form-check-input" v-model="$v.form.hasExpirationDate.$model" type="checkbox"
                          id="featuredCheckbox" />
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3" v-if="$v.form.hasExpirationDate.$model">
                    <div class="col-6">
                      <Datepicker format="DD/MM/YYYY" v-model="$v.form.expirationDate.$model"
                        placeholder="Selecione a data de validade">
                      </Datepicker>
                      <div v-if="$v.form.expirationDate.$error" class="text-danger">
                        <small>Informe um data válida a partir de hoje</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-12">
              <div class="card">
                <div class="card-body ">
                  <div class="row">
                    <div class="col-auto" :class="{ 'opacity-50': !$v.form.hasMaxUsage.$model }">
                      <span class="fas fa-tag fa-medium-size text-primary"></span>
                    </div>
                    <div class="col ps-0" :class="{ 'opacity-50': !$v.form.hasMaxUsage.$model }">
                      <h3 class="mb-0">{{ 'Limite de uso do código' }}</h3>
                      <span>{{ maxUsageMessage }}</span>
                    </div>
                    <div class="col-auto d-flex align-items-center">
                      <div class="form-check form-switch">
                        <input class="form-check-input" v-model="$v.form.hasMaxUsage.$model" type="checkbox"
                          id="featuredCheckbox" />
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3" v-if="$v.form.hasMaxUsage.$model">
                    <div class="col-6">
                      <TextInput label="" :model="$v.form.maxUsage" :maxLength="3" type="text" :mask="'###'"
                        :errorMsg="'Informe a quantidade que um código pode ser usado por um paciente, entre 1 e 100 vezes'"
                        placeholder="Informe a quantidade limite de uso do código" class="mb-0" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12">
              <div class="card">
                <div class="card-body ">
                  <div class="row">
                    <div class="col-auto" :class="{ 'opacity-50': !$v.form.onlyPremium.$model }">
                      <span class="fas fa-crown fa-medium-size text-primary"></span>
                    </div>
                    <div class="col ps-0" :class="{ 'opacity-50': !$v.form.onlyPremium.$model }">
                      <h3 class="mb-0">{{ 'Limitar uso para assinantes' }}</h3>
                      <span>{{ onlyPremiumMessage }}</span>
                    </div>
                    <div class="col-auto d-flex align-items-center">
                      <div class="form-check form-switch">
                        <input class="form-check-input" v-model="$v.form.onlyPremium.$model" type="checkbox"
                          id="featuredCheckbox" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <form>
            <div class="row">
              <div class="col-12 text-center">
                <span class="text-danger text-center">{{ errorMessage }}</span>
              </div>
            </div>
            <FormButton label="Adicionar" :disabled="$v.$invalid" :loading="submitting" @onClick="submit" />
          </form>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import AddCouponController from "./AddCouponController";
export default AddCouponController;
</script>
<style scoped></style>
