export default {
  mounted() {
    this.loadingItems = true
    this.getData()
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://cdn.jsdelivr.net/gh/google/code-prettify@master/loader/run_prettify.js')
    document.head.appendChild(recaptchaScript)
  },
  data() {
    return {
      item: {},
      id: this.$route.params.id,
      loadingItems: false,
      message: '',
      copied: false
    };
  },
  methods: {
    async getData() {
      try {
        const res = await this.$userService.showCrash(this.id)
        let data = res.data
        this.$set(this, 'item', data)
        const c = JSON.parse(data.message)
        this.$set(this, 'message', JSON.stringify(c, null, 4))
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingItems = false
      }
    },
    copyError() {
      this.$set(this, 'copied', true)
      setTimeout(() => {
        this.$set(this, 'copied', false)
      }, 3000);
      navigator.clipboard.writeText(this.item.message);
    }
  }
}