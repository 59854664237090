export default {
  name: 'Users',
  mounted() {
    this.loadingItems = true
    this.getData()
  },
  created() {
    window.addEventListener("resize", this.calculateWidth);
  },
  data() {
    return {
      item: {},
      id: this.$route.params.id,
      loadingItems: false,
    };
  },
  methods: {
    async getData() {
      try {
        const res = await this.$userService.show(this.id)
        let data = res.data
        data.formatted_created_at = this.$moment(data.created_at).format('DD/MM/YYYY')
        this.$set(this, 'item', data)
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingItems = false
      }
    },
    handleDelete() {
      this.$eventBus.$emit("showConfirmationAlert", {
        message: "Deseja excluir esse admin permanentemente?",
        confirmCallback: async () => {
          try {
            this.$set(this, `loadingDelete`, true);
            await this.$userService.delete(this.id)
            this.$eventBus.$emit('showToast', { title: 'Admin excluído', msg: `Admin excluído com sucesso` })
            this.$router.push({ path: `${this.$paths.admins.list}` })
          } catch (e) {
            console.log(e);
          } finally {
            this.$set(this, `loadingDelete`, false);
          }
        },
      });
    },
  }
}