<template>
  <div id="content_detail">
    <Navbar current="Detalhes do post" :link="$paths.posts.list" :linkName="'Posts'" />
    <Sidebar />
    <Container>
      <Header title="Detalhes do Post">
        <template v-slot:button v-if="!loadingItems">
          <button v-if="item.active" @click="deletePost()" class="btn btn-outline-danger ms-3">
            <i class="fa-solid fa-trash-alt me-1"></i>
            Remover
          </button>
          <router-link :to="`${$paths.posts.edit}/${id}`" tag="a" class="btn btn-primary lift ms-3">
            Editar
            <i class="fa-solid fa-pen me-1"></i>
          </router-link>
          <button v-if="!item.active" @click="approve()" class="btn btn-secondary ms-3">
            <i class="fa-solid fa-check me-1"></i>
            Aprovar
          </button>
        </template>
      </Header>

      <!-- <Header v-else title="Detalhes da Missão" /> -->
      <LoadingContainer v-if="loadingItems" />
      <div v-else>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <h3>Informações do post</h3>
              <div class="col-12 col-xl-6">
                <TextInput :readOnly="true" label="Nome" :value="item.owner?.name" />
              </div>
              <div class="col-12 col-xl-6">
                <TextInput :readOnly="true" label="Categoria" :value="item.category?.title" />
              </div>
              <div class="col-12 col-xl-6">
                <TextInput :readOnly="true" label="tipo" :value="item.formatted_type" />
              </div>
              <div class="col-12 col-xl-6">
                <TextInput :readOnly="true" label="Criado em" :value="item.formatted_date" />
              </div>
              <div class="col-12 col-xl-12">

                <template v-if="item.image_url">
                  <div class="form-group">
                    <div>
                      <label for="post-img" class="form-label text-muted">Imagem</label>
                    </div>
                    <img id="post-img" :src="`${$baseImageUrl}${item.image_url}`" alt="..." class="avatar-img rounded-4"
                      style="height:110px; width: 200px; object-fit: fill">
                  </div>
                </template>

              </div>
              <div class="col-12 col-xl-12">
                <TextInput :readOnly="true" label="Título" :value="item.title" />
              </div>
              <div class="col-12 col-xl-12">
                <TextAreaInput :rows="25" :readOnly="true" label="Texto" :value="item.text || ''" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import PostDetailController from "./PostDetailController";
export default PostDetailController;
</script>
