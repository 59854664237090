<template>
  <div id="videos_detail">
    <Navbar current="Detalhes do vídeo" :link="$paths.videos.list" :linkName="'Videos'" />
    <Sidebar />
    <Container>
      <Header title="Detalhes do vídeo">
        <template v-slot:button>
          <button @click="handleDelete" class="btn btn-link text-danger ms-3">
            <i class="fa-solid fa-trash-alt me-1"></i>
            Remover
          </button>
        </template>
      </Header>
      <LoadingContainer v-if="loadingItems" />
      <div v-else>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <h3>Informações</h3>
              <div class="col-12">
                <TextInput :readOnly="true" label="Descrição" :value="item.description" />
              </div>
              <div class="col-12">
                <p class="form-label text-muted">Thumbnail</p>
                <img :src="item.formatted_thumbnail" alt="" class="avatar avatar-xxl mb-5">
              </div>
              <div class="col-12 col-xl-12">
                <p class="form-label text-muted">Video</p>
                <img :src="item.target_image" alt="" class="avatar avatar-xs mb-2 rounded">
                <video controls class="video w-100" :src="item.formatted_video">
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import VideoDetailController from "./VideoDetailController";
export default VideoDetailController;
</script>
