
String.prototype.capitalizeFirst = function () {
    let res = this
    try {
        res = this.charAt(0).toUpperCase() + this.slice(1);
    } catch (e) {
        console.log(e)
    } finally {
        return res
    }
}
String.prototype.limitString = function (limit = 40) {
    let res = this
    try {
        res = this.length >= limit ? `${this.substr(0, limit - 1)}...` : this
    } catch (e) {
        console.log('erro', e)
        return res
    }
    finally {
        return res
    }
}
String.prototype.clear = function () {
    let res = this
    try {
        res = this.replace(/[^\w\s]/gi, '').replace(' ', '').replace(/[_-]/g, "")
    } catch (e) {
        return res
    }
    finally {
        return res
    }
}
String.prototype.currencyToNumber = function () {
    let res = this
    try {
        res = this.replace('R$ ', '').replace(',', '.')
    } catch (e) {
        return res
    }
    finally {
        return res
    }
}

