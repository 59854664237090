<template>
  <div id="users_detail">
    <Navbar current="Detalhes do psicólogo" :link="$paths.psychologists.list" :linkName="'Psicólogos'" />
    <Sidebar />
    <Container>
      <Header title="Detalhes do Psicólogo" v-if="!loadingItems">
        <template v-slot:button>
          <div v-if="!item.deleted_at">
            <button @click="changeEmailClicked()" class="btn btn-outline-secondary ms-3">
              <i class="fa-solid fa-envelope"></i>
              Alterar email
            </button>
            <button v-if="!item?.block_agenda" @click="handleChangeAgenda()" class="btn btn-outline-secondary ms-3">
              <i class="fa-solid fa-calendar-xmark"></i>
              Bloquear agenda
            </button>
            <button v-else @click="handleChangeAgenda()" class="btn btn-outline-secondary ms-3">
              <i class="fa-solid fa-calendar-check"></i>
              Desbloquear agenda
            </button>
            <button v-if="item.active" @click="handleChangeActive()" class="btn btn-outline-secondary ms-3">
              <i class="fa-solid fa-lock"></i>
              Desativar
            </button>
            <button v-else @click="handleChangeActive()" class="btn btn-light ms-3">
              <i class="fa-solid fa-lock-open"></i>
              Ativar
            </button>
            <button v-if="item.approved" @click="handleChangeApprove()" class="btn btn-outline-danger ms-3">
              <i class="fa-solid fa-ban"></i>
              Desaprovar
            </button>
            <button v-else @click="handleChangeApprove()" class="btn btn-success ms-3">
              <i class="fa-solid fa-circle-check"></i>
              Aprovar
            </button>
            <button v-if="!item.isPremium" @click="handleGivePremium()" class="btn btn-outline-secondary ms-3">
              <i class="fa-solid fa-crown"></i>
              Tornar premium
            </button>
            <button v-if="item.isPremium && item.subscription?.subscription_plan_id == 2" @click="handleRemovePremium()"
              class="btn btn-outline-secondary ms-3">
              <span>Remover premium</span>
            </button>
          </div>
        </template>
      </Header>

      <LoadingContainer v-if="loadingItems" />

      <div v-else>
        <div class="row">
          <div class="col-12 col-xl-8">
            <div class="card">
              <ul class="nav nav-tabs ps-4 pt-3" id="myTab" role="tablist">
                <li class="nav-item active" role="presentation">
                  <button class="nav-link active" id="account-tab" data-bs-toggle="tab" data-bs-target="#account"
                    type="button" role="tab" aria-controls="account" aria-selected="true">
                    Cadastro
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button"
                    role="tab" aria-controls="profile" aria-selected="false">
                    Perfil
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="schedule-tab" data-bs-toggle="tab" data-bs-target="#schedule" type="button"
                    role="tab" aria-controls="schedule" aria-selected="false">
                    Horários
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="bank-tab" data-bs-toggle="tab" data-bs-target="#bank" type="button"
                    role="tab" aria-controls="bank" aria-selected="false">
                    Dados bancários
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="appointments-tab" data-bs-toggle="tab" data-bs-target="#appointments"
                    type="button" role="tab" aria-controls="appointments" aria-selected="false">
                    Sessões
                    <span class="badge bg-primary-soft" style="font-size: 0.8rem;">{{ item.schedule?.length }}</span>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="transfer-tab" data-bs-toggle="tab" data-bs-target="#transfer" type="button"
                    role="tab" aria-controls="transfer" aria-selected="false">
                    Repasses
                    <span class="badge bg-primary-soft" style="font-size: 0.8rem;">{{ item.payouts?.length }}</span>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="review-tab" data-bs-toggle="tab" data-bs-target="#review" type="button"
                    role="tab" aria-controls="review" aria-selected="false">
                    Avaliações
                    <span class="badge bg-primary-soft" style="font-size: 0.8rem;">{{ item.latest_ratings?.length
                    }}</span>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="coupons-tab" data-bs-toggle="tab" data-bs-target="#coupons" type="button"
                    role="tab" aria-controls="coupons" aria-selected="false">
                    Cupons
                    <span class="badge bg-primary-soft" style="font-size: 0.8rem;">{{ item.coupons?.length }}</span>
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">

                <!-- Account Tab -->
                <div class="tab-pane card-body fade show active" id="account" role="tabpanel"
                  aria-labelledby="account-tab">
                  <PsiDetailRegisterInfo :item="item" @changeCallProvider="changeCallProvider" />
                </div>

                <!-- Profile Tab -->
                <div class="tab-pane card-body fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                  <PsiDetailProfile :item="item" />
                </div>

                <!-- Schedule Tab -->
                <div class="tab-pane fade" id="schedule" role="tabpanel" aria-labelledby="schedule-tab">
                  <PsiDetailSchedules :item="item" />
                </div>

                <!-- Bank Tab -->
                <div class="tab-pane card-body fade" id="bank" role="tabpanel" aria-labelledby="bank-tab">
                  <PsiDetailBankInfo :item="item" />
                </div>

                <!-- Appointments Tab -->
                <div class="tab-pane fade" id="appointments" role="tabpanel" aria-labelledby="appointments-tab">
                  <PsiDetailAppointments :item="item" />
                </div>

                <!-- Transfer Tab -->
                <div class="tab-pane fade" id="transfer" role="tabpanel" aria-labelledby="transfer-tab">
                  <PsiDetailPayouts :item="item" />
                </div>

                <!-- Review Tab -->
                <div class="tab-pane card-body fade" id="review" role="tabpanel" aria-labelledby="review-tab">
                  <PsiDetailRatings :item="item" />
                </div>

                <!-- Coupons Tab -->
                <div class="tab-pane card-body fade" id="coupons" role="tabpanel" aria-labelledby="review-tab">
                  <PsiDetailCoupons :item="item" />
                </div>
              </div>
            </div>
          </div>
          <!-- STICKY CARD -->
          <div class="col-12 col-xl-4">
            <PsiDetailSummary :item="item" />
          </div>
        </div>
      </div>
    </Container>
    <ModalComponent :title="'Alterar email'" id="changeEmailModal">
      <template v-slot:body>
        <div class="mt-3">
          <TextInput label="" :model="$v.form.email" class="text-white" placeholder="Confirme a senha" />
          <p class="text-center text-danger">
            {{ errorMessage }}
          </p>
        </div>
      </template>
      <template v-slot:footer>
        <div>
          <FormButton label="Concluir" :loading="submitting" :disabled="$v.$invalid" class="btn w-15 btn-primary mb-3"
            @onClick="handleChangeEmail" />
        </div>
      </template>
    </ModalComponent>
  </div>
</template>
<script>
import PsychologistDetailController from "./PsychologistDetailController";
export default PsychologistDetailController;
</script>
