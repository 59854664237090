import store from '@/store'
import AddAdmins from '@/views/Admins/AddAdmins/AddAdmins.vue'
import AdminDetail from '@/views/Admins/AdminDetail/AdminDetail.vue'
import AdminPasswordUpdate from '@/views/Admins/AdminPasswordUpdate/AdminPasswordUpdate.vue'
import Admins from '@/views/Admins/Admins.vue'
import EditAdmins from '@/views/Admins/EditAdmins/EditAdmins.vue'
import Home from '@/views/Home/Home.vue'
import Login from '@/views/Login/Login.vue'
import NotFound from '@/views/NotFound/NotFound.vue'
import Ratings from '@/views/Ratings/Ratings.vue'
import ReportedRatings from '@/views/ReportedRatings/ReportedRatings.vue'
import UserDetail from '@/views/Users/UserDetail/UserDetail.vue'
import UserEdit from '@/views/Users/UserEdit/UserEdit.vue'

import Users from '@/views/Users/Users.vue'
import Reports from '@/views/Reports/Reports.vue'
import Appointments from '@/views/Appointments/Appointments.vue'
import AppointmentDetail from '@/views/Appointments/AppointmentDetail/AppointmentDetail.vue'
import Posts from '@/views/Posts/Posts.vue'
import PostDetail from '@/views/Posts/PostDetail/PostDetail.vue'
import PostAdd from '@/views/Posts/PostAdd/PostAdd.vue'
import Videos from '@/views/Videos/Videos.vue'
import VideoDetail from '@/views/Videos/VideoDetail/VideoDetail.vue'
import VideoAdd from '@/views/Videos/VideoAdd/VideoAdd.vue'
import Contracts from '@/views/Contracts/Contracts.vue'
import Crashes from '@/views/Crashes/Crashes.vue'
import CrashDetail from '@/views/Crashes/CrashDetail/CrashDetail.vue'
import ContractDetail from '@/views/Contracts/ContractDetail/ContractDetail.vue'
import Psychologists from '@/views/Psychologists/Psychologists.vue'
import PsychologistDetail from '@/views/Psychologists/PsychologistDetail/PsychologistDetail.vue'
// import AppointmentDetail from '@/views/CustomerService/Appointments/AppointmentDetail/AppointmentDetail.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'
import paths from './paths'

import Coupons from '@/views/Coupons/Coupons.vue'
import CouponDetail from '@/views/Coupons/CouponDetail/CouponDetail.vue'
import AddCoupon from '@/views/Coupons/AddCoupon/AddCoupon.vue'
import Partners from '@/views/Partners/Partners'
import PartnerDetail from '@/views/Partners/PartnerDetail/PartnerDetail.vue'
import AddPartner from '@/views/Partners/AddPartner/AddPartner.vue'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    redirect: {
      name: 'Home'
    }
  },
  {
    path: paths.login,
    name: 'Login',
    component: Login,
    props: true,
  },

  {
    path: paths.home,
    name: 'Home',
    component: Home,
    props: true,
    meta: { requiresAuth: true }
  },


  //EMPRESAS PARCEIRAS
  {
    path: paths.partners.list,
    name: 'Usuários',
    component: Partners,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.partners.detail}/:id`,
    name: 'Detalhes da empresa',
    component: PartnerDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.partners.add}`,
    name: 'Add Empresa',
    component: AddPartner,
    props: true,
    meta: { requiresAuth: true }
  },



  //USERS
  {
    path: paths.users.list,
    name: 'Pacientes',
    component: Users,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.users.detail}/:id`,
    name: 'Detalhes do Paciente',
    component: UserDetail,
    props: true,
    meta: { requiresAuth: true }
  },

  {
    path: `${paths.users.edit}/:id`,
    name: 'Editar paciente',
    component: UserEdit,
    props: true,
    meta: { requiresAuth: true }
  },


  //PSICOLOGOS
  {
    path: `${paths.psychologists.list}/:tab?`,
    name: 'Psicólogos',
    component: Psychologists,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.psychologists.detail}/:id`,
    name: 'Detalhes do Psi',
    component: PsychologistDetail,
    props: true,
    meta: { requiresAuth: true }
  },


  //CONTRATOS
  {
    path: paths.contracts.list,
    name: 'Contratos',
    component: Contracts,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.contracts.list}/:key`,
    name: 'Detalhes do Contrato',
    component: ContractDetail,
    props: true,
    meta: { requiresAuth: true }
  },

  //CUPONS
  {
    path: paths.coupons.list,
    name: 'Cupons',
    component: Coupons,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.coupons.detail}/:id`,
    name: 'Detalhes do Código',
    component: CouponDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.coupons.add}`,
    name: 'Adicionar Código',
    component: AddCoupon,
    props: true,
    meta: { requiresAuth: true }
  },

  //VIDEOS DA HOME
  {
    path: paths.videos.list,
    name: 'Videos',
    component: Videos,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.videos.detail}/:id`,
    name: 'Detalhes do Video',
    component: VideoDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.videos.add}`,
    name: 'Adicionar Video',
    component: VideoAdd,
    props: true,
    meta: { requiresAuth: true }
  },

  //POSTS
  {
    path: paths.posts.list,
    name: 'Posts',
    component: Posts,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.posts.detail}/:id`,
    name: 'Detalhes do post',
    component: PostDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.posts.add}`,
    name: 'Adicionar post',
    component: PostAdd,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.posts.edit}/:id`,
    name: 'Editar post',
    component: PostAdd,
    props: true,
    meta: { requiresAuth: true }
  },

  //REPORTS
  {
    path: paths.reports.list,
    name: 'Reports',
    component: Reports,
    props: true,
    meta: { requiresAuth: true }
  },
  //APPOINTMENTS
  {
    path: `${paths.appointments.list}/:tab?`,
    name: 'Sessões',
    component: Appointments,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.appointments.detail}/:id`,
    name: 'Detalhes da Sessão',
    component: AppointmentDetail,
    props: true,
    meta: { requiresAuth: true }
  },

  //ADMINS
  {
    path: paths.admins.list,
    name: 'Administradores',
    component: Admins,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.admins.detail}/:id`,
    name: 'Detalhe do Administrador',
    component: AdminDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.admins.password_update}/:id`,
    name: 'Editar Senha de Administrador',
    component: AdminPasswordUpdate,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.admins.edit}/:id`,
    name: 'Editar Administrador',
    component: EditAdmins,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.admins.add}`,
    name: 'Adicionar Administrador',
    component: AddAdmins,
    props: true,
    meta: { requiresAuth: true }
  },

  // RATINGS
  {
    path: `${paths.ratings.list}`,
    name: 'Avaliações',
    component: Ratings,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.ratings.reported}`,
    name: 'Avaliações Reportadas',
    component: ReportedRatings,
    props: true,
    meta: { requiresAuth: true }
  },

  {
    path: '*',
    redirect: {
      name: 'NotFound'
    }
  },
  {
    path: paths.not_found,
    name: 'NotFound',
    component: NotFound
  },
  {
    path: paths.crashes.list,
    name: 'Crashes',
    component: Crashes
  },
  {
    path: `${paths.crashes.detail}/:id`,
    name: 'CrashDetail',
    component: CrashDetail
  },
  {
    path: `${paths.password_update}/:type/:id`,
    name: 'Editar Senha',
    component: AdminPasswordUpdate,
    props: true,
    meta: { requiresAuth: true }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  paths
})

function isLogged() {
  return !!store.state.userSession
}

router.beforeEach((to, from, next) => {

  if (to.meta.requiresAuth && !isLogged()) {
    next(paths.login);
  } else {
    next();
  }
});


export default router
