import NetworkService from "@/services/NetworkService";

export default class VideoService {
  constructor() {
    this.network = new NetworkService();
  }

  static APP_TARGET = {
    PSI: 'psy',
    USER: 'patient',
  }

  list = (filters) => {
    return this.network.get(this.network.endpoints.homeVideos, filters);
  };
  delete = (id) => {
    return this.network.delete(`${this.network.endpoints.homeVideos}/${id}`);
  };
  show = (id) => {
    //TODO: nao tem show de video no back ainda.. nem mexe nisso vinnie.. é coisa antiga.. deixa reclamarem kkkk
    return this.network.get(`${this.network.endpoints.homeVideos}/${id}`);
  };
  create = (params) => {
    return this.network.postMultipart(this.network.endpoints.homeVideos, params);
  };
  prepareVideo(item) {
    try {
      item.formatted_thumbnail = `${process.env.VUE_APP_IMG_BASE_URL}${item.thumbnail_path}`
      item.target_image = item.target == VideoService.APP_TARGET.PSI ? require('../assets/img/psi-pro-icon.png') : require('../assets/img/psi-user-icon.png')
      item.formatted_video = `${process.env.VUE_APP_IMG_BASE_URL}${item.video_path}`
    } catch (e) {
      console.log(e);
    } finally {
      console.log(item)
      return item;
    }
  }
}
