<template>
  <div id="app">
    <router-view />
    <NotificationToast />
    <ConfirmationAlert />
  </div>
</template>

<script>
import NotificationToast from '@/components/NotificationToast'
// import { startFirebase } from './firebaseinit';
export default {
  name: "App",
  components: {
    NotificationToast
  },
  created() {
    document.title = 'Psiapp | Administrador'
  },
  mounted() {
    // startFirebase()
  }
};
</script>

<style lang="scss"></style>
