import AppointmentsService from '@/services/AppointmentsService';
import EventsComponent from './EventsComponent.vue';
import FeedbackComponent from './FeedbackComponent.vue';
import InvoiceComponent from './InvoiceComponent.vue';

import InfoComponent from './InfoComponent.vue';
import ProfileComponent from './ProfileComponent.vue';
import { toggleModal } from '@/components/ModalComponent.vue';
import { required } from 'vuelidate/lib/validators';
export default {
  mounted() {
    this.loadingItems = true
    this.getData()
  },
  computed: {
    userSession() {
      return this.$store.state.userSession;
    },
    canCancelFreeAppointment() {
      return this.item.value == 0
        && this.item.type == AppointmentsService.TYPES.SCHEDULED
        && (
          this.item.status == AppointmentsService.STATUS.AGUARDANDO
          || this.item.status == AppointmentsService.STATUS.ANDAMENTO
          || this.item.status == AppointmentsService.STATUS.AGENDADO
        )
    },
    canUpdateAppointmentDate() {
      return this.item.type == AppointmentsService.TYPES.SCHEDULED
        && (
          this.item.status == AppointmentsService.STATUS.AGUARDANDO
          || this.item.status == AppointmentsService.STATUS.ANDAMENTO
          || this.item.status == AppointmentsService.STATUS.AGENDADO
        )
    }
  },
  created() {

  },
  data() {
    return {
      item: {},
      id: this.$route.params.id,
      loadingItems: false,
      submitting: false,
      errorMessage: '',
      dateModalId: 'changeDateModal',
      toggleModal: toggleModal,
      form: {
        date: ''
      }
    };
  },
  validations: {
    form: {
      date: { required },
    }
  },
  methods: {
    async getData() {
      try {
        const res = await this.$appointmentsService.show(this.id)
        let data = this.$appointmentsService.prepareAppointment(res.data)
        this.$set(this, 'item', data)
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingItems = false
      }
    },
    handleCancelAppointment() {
      this.$eventBus.$emit("showConfirmationAlert", {
        title: "Deseja cancelar esta sessão?",
        message: "Deseja continuar com o cancelamento?",
        confirmCallback: async () => {
          try {
            await this.$appointmentsService.update(this.id, { status: AppointmentsService.STATUS.CANCELADO, reason_for_cancellation: `Sessão gratuita cancelada por ${this.userSession.name}` })
            this.getData()
            this.$eventBus.$emit("showToast", {
              title: "Sessão cancelada com sucesso",
              msg: `A sessão gratuita foi cancelada`,
            });
          } catch (e) {
            console.log(e);
          } finally {
          }
        },
      });
    },
    async handleChangeDate() {
      try {
        this.errorMessage = ''
        this.submitting = true
        await this.$appointmentsService.update(this.id, { date: this.form.date })
        toggleModal(this.dateModalId)
        this.getData()
        this.$eventBus.$emit("showToast", {
          title: "Data da sessão alterada com sucesso",
          msg: `A data da sessão foi alterada`,
        });
      } catch (e) {
        this.errorMessage = e.message
        console.log(e);
      } finally {
        this.submitting = false
      }
    }

  },
  components: {
    InfoComponent,
    EventsComponent,
    FeedbackComponent,
    ProfileComponent,
    InvoiceComponent
  }
}
