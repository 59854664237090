<template>
  <div id="add-coupon">
    <Navbar current="Adicionar empresa" :link="$paths.coupons.list" :linkName="'Empresas parceiras'" />
    <Sidebar />
    <Container>
      <Header title="Adicionar Empresa" />
      <div class="card">
        <div class="card-body">
          <h3>Informações</h3>
          <div class="row">
            <div class="col-12 col-md-12">
              <TextInput label="Nome da empresa" :required="true" :model="$v.form.company_name" :maxLength="100"
                type="text" :errorMsg="' Informe um código com no mínimo 6 caractéres'" placeholder="Psiapp" />
            </div>
            <div class="col-12 col-md-12">
              <TextInput label="Código" :required="true" :model="$v.form.code" :maxLength="20" type="text"
                :inputStyle="'text-transform:uppercase'"
                :errorMsg="' Informe um código com no mínimo 6 caractéres somente com letras e numeros e sem espaços'"
                placeholder="ANDRE3000" />
            </div>
            <div class="col-12 col-md-12">
              <TextInput label="Desconto(%)" :model="$v.form.discount" :maxLength="3" type="text" placeholder="10%"
                :errorMsg="'Informe a porcentagem de desconto entre 1% e 30%'" :mask="'##'" />
              <div class="bg-light rounded p-3 mb-4" style="margin-top: -10px;">
                <small>Ao ativar a parceria com o código da empresa, não será possível para o paciente usar um cupom de
                  desconto e o
                  benefício nas consultas simultaneamente.</small>
              </div>
            </div>
          </div>
          <form>
            <div class="row">
              <div class="col-12 text-center">
                <span class="text-danger text-center">{{ errorMessage }}</span>
              </div>
            </div>
            <FormButton label="Adicionar" :disabled="$v.$invalid" :loading="submitting" @onClick="submit" />
          </form>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import AddPartnerController from "./AddPartnerController";
export default AddPartnerController;
</script>
<style scoped></style>
