import moment from 'moment';
import { email, required, sameAs, minLength, helpers, minValue, maxValue, withMessage } from 'vuelidate/lib/validators';
export default {
  name: 'AddCoupon',
  mounted() { },
  data() {
    return {
      errorMessage: '',
      submitting: false,
      appointmentThreshold: process.env.VUE_APP_APPOINTMENT_COUPON_TRESHOLD,
      form: {
        code: '',
        discount: '',
        maxUsage: '',
        expirationDate: '',
        hasExpirationDate: true,
        hasMaxUsage: true,
        onlyPremium: true,
      },
    };
  },
  computed: {
    maxUsageMessage() {
      return this.form.hasMaxUsage ? 'O uso desse código será limitado para cada paciente' : 'Este código poderá ser aplicado por cada paciente sem limites de uso'
    },
    expirationDateMessage() {
      return this.form.hasExpirationDate ? 'Este código poderá ser utilizado pelos pacientes até a data informada' : 'Este código poderá ser utilizado pelos pacientes sem limite de data'
    },
    onlyPremiumMessage() {
      return this.form.onlyPremium ? 'Este código só poderá ser utilizado por psicólogos assinantes' : 'Este código poderá ser utilizado por todos os psicólogos'
    }
  },
  validations() {
    return {
      form: {
        code: {
          required,
          minLength: minLength(6),
          validChars: (value) => {
            var pattern = new RegExp('^[a-zA-Z0-9]+$');
            return pattern.test(value)
          },
        },
        discount: {
          required,
          maxValue: maxValue(90),
          minValue: minValue(5),
        },
        maxUsage: this.form.hasMaxUsage ? {
          required: required,
          minValue: minValue(1),
          maxValue: maxValue(999),
        } : '',
        expirationDate: this.form.hasExpirationDate ? {
          required: required,
          minDate: (value) => {
            const date = moment(value)
            return date.isValid() && date.isSameOrAfter(moment(), 'd')
          },
        } : '',
        hasExpirationDate: '',
        hasMaxUsage: '',
        onlyPremium: '',
      }
    }
  },
  methods: {
    async submit() {
      try {
        this.submitting = true
        this.errorMessage = ''
        const values = JSON.parse(JSON.stringify(this.form))

        const params = {
          code: values.code.toUpperCase(),
          percent_off: values.discount,
          redeem_by: values.hasExpirationDate ? moment(values.expirationDate).format('YYYY-MM-DD') : '',
          max_redemptions_customers: values.hasMaxUsage ? values.maxUsage : '',
          subscriber_only: values.onlyPremium
        }

        const res = await this.$couponsService.create(params)

        this.$eventBus.$emit('showToast', { title: 'Código criado', msg: `Código personalizado adicionado para todos os psicólogos` })
        this.$router.push({ path: `${this.$paths.coupons.list}` })
      } catch (e) {
        this.errorMessage = e.message
      } finally {
        this.submitting = false
      }
    },
  }
};