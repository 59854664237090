<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="card mb-0">
          <div class="card-body text-start">
            <p class="text-muted mb-2">
              Cupons
            </p>
            <span class="h2 fw-bold mb-0">
              <i class="fa-solid fa-tags"></i>
              {{ item.coupons?.length }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <hr class="my-4">
    <h2 class="fw-bold mb-4">Cupons</h2>
    <div class="row">
      <div class="col">
        <table ref="myTable" class="table table-sm table-nowrap card-table table-hover cursor-pointer">
          <tbody class="list" v-if="item.coupons?.length">
            <tr class="text-muted">
              <td>Código</td>
              <td>Criação</td>
              <td>Validade</td>
              <td>Status</td>
            </tr>
            <tr v-for="(item, index) in item.coupons" v-bind:key="'tr-' + index"
              @click="handleCouponDetail(item.id, index)">
              <td>{{ item.code }}</td>
              <td>
                <time>{{
                  item.formatted_created_at
                }}</time>
              </td>
              <td>
                <time>{{
                  item.formatted_expiration_date
                }}</time>
              </td>
              <td :class="`${item.formatted_status_obj.textClass}`">
                <span :class="item.formatted_status_obj.icon"></span>
                {{ item.formatted_status_obj.name }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="item.latest_ratings?.length <= 0" class="py-6">
        <p class="text-muted text-center">
          Nenhuma avaliação
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PsiDetailCoupons",
  props: {
    item: {
      type: Object,
      default: () => { },
    },
  },
}
</script>