import UserService from '@/services/UserService';
import { EventBus } from '@/utils/EventBus'
export const changeActive = (item, callBack) => {
  const userService = new UserService()
  EventBus.$emit("showConfirmationAlert", {
    title: item.active ? "Deseja inativar este usuário?" : "Deseja ativar este usuário?",
    message: item.active ? "Ao inativar este usuário, ele irá perder o acesso ao app e não ficará visível para outras pessoas até que seja ativado novamente. Deseja continuar?" : "Essa ação permitirá que o usuário acesse o app novamente. Deseja continuar?",
    confirmCallback: async () => {
      try {
        await userService.update(item.id, { active: !item.active })
        callBack()
      } catch (e) {
        console.log(e);
      } finally {
      }
    },
  });
}

export const changeApprove = (item, callBack) => {
  const userService = new UserService()
  EventBus.$emit("showConfirmationAlert", {
    title: item.approved ? "Deseja desaprovar este usuário?" : "Deseja aprovar este usuário?",
    message: item.approved ? "Ao desaprovar este usuário, ele não poderá usar as funcionalidades do app até que seja aprovado novamente. Deseja continuar?" : "Ao aprovar este usuário, eles poderá utilizar as funcionalidades do app. Deseja continuar?",
    confirmCallback: async () => {
      try {
        await userService.update(item.id, { approved: !item.approved })
        callBack()
      } catch (e) {
        console.log(e);
      } finally {
      }
    },
  });
}
export const changeAgenda = (item, callBack) => {
  const userService = new UserService()
  EventBus.$emit("showConfirmationAlert", {
    title: !item.block_agenda ? "Deseja bloquear a agenda deste usuário?" : "Deseja desbloquear a agenda deste usuário?",
    message: !item.block_agenda ? "Ao bloquear a agenda, o usuário não ficará visível no app para agendamento de consultas. Deseja continuar?" : "Ao desbloquear a agenda, o usuário voltará a ser visível no app para agendamento de consultas. Deseja continuar?",
    confirmCallback: async () => {
      try {
        await userService.update(item.id, { block_agenda: !item.block_agenda })
        callBack()
      } catch (e) {
        console.log(e);
      } finally {
      }
    },
  });
}

export const removePremium = (item, callBack) => {
  const userService = new UserService()
  EventBus.$emit("showConfirmationAlert", {
    title: "Deseja remover a assinatura deste psi?",
    message: "Ao remover a assinatura, o psi não terá mais os benefícios da conta Premium. Deseja continuar?",
    confirmCallback: async () => {
      try {
        await userService.removePremium(item.id)
        callBack()
      } catch (e) {
        console.log(e);
      } finally {
      }
    },
  });
}
export const givePremium = (item, callBack) => {
  const userService = new UserService()
  EventBus.$emit("showConfirmationAlert", {
    title: "Deseja dar uma assinatura premium para este psi?",
    message: "O psi passará a ter uma assinatura Premium com todos os benefícios. Deseja continuar?",
    confirmCallback: async () => {
      try {
        await userService.givePremium(item.id)
        callBack()
      } catch (e) {
        console.log(e);
      } finally {
      }
    },
  });
}
export const changeEmail = async (item, callBack) => {
  const userService = new UserService()
  try {
    let res = await userService.update(item.id, { email: item.email })
    callBack(res)
  } catch (error) {
    console.log(error);
  }
}
