<template>
  <div id="edit-admins">
    <Navbar
      current="Editar paciente"
      :link="$paths.users.list"
      :sublink="`${$paths.users.detail}/${id}`"
      :linkName="'Pacientes'"
      :sublinkName="'Detalhes do paciente'"
    />
    <Sidebar />
    <Container>
      <Header title="Editar Paciente" />
      <div class="card">
        <div class="card-body">
          <form>
            <TextInput
            label="Apelido"
            type="text"
            :model="$v.form.nickname"
            placeholder="Apelido"
          />
            <TextInput
              label="Nome"
              type="text"
              :model="$v.form.name"
              placeholder="Nome"
            />

            <div class="row">
              <div class="col-3">  <TextInput
                label="Código do país"
                type="text"
                :model="$v.form.country_code"
                placeholder="Código do país"
              /></div>

              <div class="col">    <TextInput
                label="Telefone"
                type="text"
                :model="$v.form.phone"
                placeholder="Telefone"
              /></div>
            </div>

          
        
            <TextInput
              label="CPF"
              type="text"
              :model="$v.form.document_number"
              placeholder="CPF"
            />
            <TextInput :model="$v.form.email"  type="email" label="Email" />
            <div class="row">
              <div class="col-12 text-center">
                <span class="text-danger text-center">{{ errorMessage }}</span>
              </div>
            </div>
            <FormButton
              label="Editar"
              :loading="submitting"
              :disabled="$v.$invalid"
              @onClick="submit"
            />
          </form>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import UserEditController from "./UserEditController";
export default UserEditController;
</script>
<style scoped>
</style>
