<template>
  <router-link tag="tr" :to="`${$paths.appointments.detail}/${item.id}?index=${index}`">
    <td v-if="columnsToDisplay.id.visible">
      {{ item.id }}
    </td>
    <td v-if="columnsToDisplay.psi.visible">
      <div class="avatar avatar-xs align-middle me-2">
        <img v-if="item.psychologist?.profile_image" :src="`${$baseImageUrl}${item.psychologist?.profile_image}`"
          alt="..." class="avatar-img rounded-circle" />
        <img v-if="!item.psychologist?.profile_image" src="@/assets/img/avatar_placeholder.svg" alt="..."
          class="avatar-img rounded-circle" />
      </div>
      <span>
        ({{ item.psychologist?.id }}) <span class="fw-bold">{{ item.psychologist?.name }}</span>
        <span v-if="item.psychologist?.deleted_at" class="text-danger fw-bold">
          (Excluído)
        </span>
      </span>
    </td>
    <td v-if="columnsToDisplay.patient.visible">
      <div class="avatar avatar-xs align-middle me-2">
        <img src="@/assets/img/avatar_placeholder.svg" alt="..." class="avatar-img rounded-circle" />
      </div>
      <span>
        ({{ item.user?.id }}) <span class="fw-bold">{{ item.user?.name }}</span>
        <span v-if="item.user.deleted_at" class="text-danger fw-bold">
          (Excluído)
        </span>
      </span>
    </td>
    <td v-if="columnsToDisplay.created_at.visible">
      <p class="card-text small text-body-secondary">
        <i class="fa-regular fa-calendar"></i>
        <time>
          {{ $moment(item.created_at).format("DD/MM/YY") }}
        </time>
        <i class="fa-regular fa-clock ms-"></i>
        <time>
          {{ $moment(item.created_at).format("HH:mm") }}
        </time>
      </p>
    </td>
    <td v-if="columnsToDisplay.date.visible">
      <p class="card-text small text-body-secondary">
        <i class="fa-regular fa-calendar"></i>
        <time>
          {{ $moment(item.date).format("DD/MM/YY") }}
        </time>
        <i class="fa-regular fa-clock ms-"></i>
        <time class="fw-bold">
          {{ $moment(item.date).format("HH:mm") }}
        </time>
      </p>
    </td>
    <td v-if="columnsToDisplay.value.visible">
      R${{ item.value }}
    </td>
    <td v-if="columnsToDisplay.payment.visible">
      <span :class="{ 'fw-bold': item.pg_status == 'succeeded' }">
        <i :class="item.paymentStatus?.iconClass"></i>
        {{ item.paymentStatus?.title }}
      </span>
    </td>
    <td v-if="columnsToDisplay.recurrency.visible">
   
      <span class="fw-bold mb-0" v-if="item.recurrency_id">
        <i class="fa-solid fa-circle fa-xs text-success me-2" v-if="item.recurrency.status == 'active'"></i>
        <i class="fa-solid fa-circle-xmark text-danger me-2" v-else></i>
        <span v-if="item.recurrency.frequency == 'weekly' && item.recurrency.interval == 1"> Semanal</span>
        <span v-else-if="item.recurrency.frequency == 'weekly' && item.recurrency.interval == 2"> Quinzenal</span>

        <span v-else-if="item.recurrency.frequency == 'monthly' && item.recurrency.interval == 1"> Mensal</span>

        <span v-else> {{ item.recurrency.frequency }} Interval {{item.recurrency.interval}}</span>
      </span>

      <span class="mb-0" v-else>
        <i class="fa-solid fa-circle fa-xs text-muted me-2"></i>
        <span>Avulsa</span>
      </span>
    </td>
    <td v-if="columnsToDisplay.status.visible">
      <span v-if="item.status != 'em andamento'">
        <i :class="item.statusObject?.iconClass"></i>
        {{ item.statusObject?.title }}
      </span>
      <span v-else class="fw-bold">
        <div class="spinner-grow spinner-grow-sm text-warning" style="width: .81rem; height: .81rem;" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        {{ item.statusObject?.title }}
      </span>
    </td>
    <!-- <td v-if="columnsToDisplay.shortcut.visible">
      <a :href="`${$paths.appointments.detail}/${item.id}?index=${index}`" class="btn btn-sm btn-white lift"
        target="_blank">
        <span class="fe fe-arrow-up-right"></span>
      </a>
    </td> -->
  </router-link>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: {},
    },
    index: Number,
    //array de ids das colunas que vc quer que apareçam... caso queira todas, só nao passar o parametro ( os ids sao os do columsToDisplay )
    visibleColumns: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    //funciona igual o select do laravel: O default mostra todas, mas se passar as que quer, so mostra elas
    if (this.visibleColumns?.length) {
      Object.keys(this.columnsToDisplay).map(key => {
        this.columnsToDisplay[key].visible = false
      })
      this.visibleColumns.map(elem => {
        this.columnsToDisplay[elem].visible = true
      })
    }
    console.log('columns', this.columnsToDisplay)
  },
  data() {
    return {
      columnsToDisplay: {
        id: { visible: true },
        psi: { visible: true },
        patient: { visible: true },
        created_at: { visible: true },
        date: { visible: true },
        value: { visible: true },
        payment: { visible: true },
        flow: { visible: true },
        status: { visible: true },
        shortcut: { visible: false },
        recurrency: { visible: true },

      }
    }
  }
}
</script>
<style>
td:last-child {
  text-align: end !important;
}
</style>
