<template>
  <div id="coupons_detail">
    <Navbar current="Detalhes do código" :link="$paths.coupons.list" :linkName="'Cupons'" />
    <Sidebar />
    <Container>
      <Header title="Detalhes do código">
        <template v-slot:button>
          <button @click="handleDelete" class="btn btn-link text-danger ms-3">
            <i class="fa-solid fa-trash-alt me-1"></i>
            Remover
          </button>
        </template>
      </Header>
      <LoadingContainer v-if="loadingItems" />
      <div v-else>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <h3>Informações</h3>
              <div class="col-12 col-xl-4">
                <TextInput :readOnly="true" label="Código" :value="item.code">
                  <template v-slot:labelContentEnd>
                    <CopyText :string="item.code" class="mb-1" />
                  </template>
                </TextInput>
              </div>
              <div class="col-12 col-xl-4">
                <TextInput :readOnly="true" label="Desconto(%)" :value="item.percent_off" />
              </div>
              <div class="col-12 col-xl-4">
                <TextInput :readOnly="true" label="Validade" :value="item.formatted_expiration_date" />
              </div>
              <div class="col-12 col-xl-6">
                <TextInput :readOnly="true" label="Limite de uso por paciente" :value="item.max_redemptions_customers" />
              </div>
              <div class="col-12 col-xl-6">
                <TextInput :readOnly="true" label="Qtd utilizada" :value="item.times_redeemed" />
              </div>
              <div class="col-12 col-xl-4">
                <TextInput :readOnly="true" label="Data de criação" :value="item.formatted_created_at" />
              </div>
              <div class="col-12 col-xl-4">
                <TextInput :readOnly="true" label="Criado por" :value="item.formatted_created_by" />
              </div>
              <div class="col-12 col-xl-4">
                <TextInput :readOnly="true" label="Status" :value="item.formatted_status_obj?.name" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import CouponDetailController from "./CouponDetailController";
export default CouponDetailController;
</script>
