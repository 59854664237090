import NetworkService from "@/services/NetworkService.js";

export default class RatingsService {
  constructor() {
    this.network = new NetworkService();
  }
  list = (params) => {
    return this.network.get(`${this.network.endpoints.ratingsReport}`, params);
  }
  validateRating(ratingId, approved) {
    return this.network.post(this.network.endpoints.reviewRating, { valid: approved, called_rating_id: ratingId })
  }
  prepareRating(data) {
    try {
      if (data.appointment && data.appointment.psychologist) {
        data.psychologist = data.appointment.psychologist
      }
    } catch (e) {
      console.log(e);
    } finally {
      return data;
    }
  }
}
