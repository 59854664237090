import ListMixinController from "@/mixins/ListMixinController";

export default {
  mixins: [ListMixinController],
  mounted() {
    this.loadingItems = true;
    this.getData();
  },
  computed: {
    userSession() {
      return this.$store.state.userSession;
    },
    filteredItems() {
      const it = this.type
        ? this.items.filter((r) => r.type == this.type)
        : this.items;
      console.log("jahbsdjljahjvskldahjvsdj", this.items);
      console.log(it);
      return it;
    },
  },
  data() {
    return {
      type: "",
      columns: [
        {
          name: "OS",
          sortName: null,
          order: null,
        },
        {
          name: "App",
          sortName: null,
          order: null,
        },
        {
          name: "Exception",
          sortName: null,
          order: null,
        },
        {
          name: "Data",
          sortName: null,
          order: null,
        },
      ],
    };
  },
  methods: {
    prepareResponse(data) {
      return data.map((elem) => ({
        ...elem,
        formatted_message: JSON.parse(elem.message).error?.message.limitString(
          100
        ),
        // statusObject: this.$tasksService.getTaskStatus(elem.status),
        // pillarObject: this.$missionsService.getMissionPillar(elem.pillar),
      }));
    },
    fetchPromise() {
      return this.$userService.listCrashes({
        ...this.filters,
        orderBy: this.columns.filter(
          (r) => r.order != null && r.sortName != null
        ),
      });
    },
  },
};
