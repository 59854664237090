import NetworkService from '@/services/NetworkService.js'
import moment from 'moment'

export default class CouponsService {
  constructor() {
    this.network = new NetworkService()
  }
  coupomStatusObj = {
    'active': {
      name: 'Ativo',
      textClass: 'text-success',
      icon: 'fas fa-check-circle'
    },
    'expired': {
      name: 'Vencido',
      textClass: 'text-muted',
      icon: 'far fa-clock'
    },

  }
  create = (params) => {
    return this.network.post(`${this.network.endpoints.coupons}`, params)
  }
  list = (params) => {
    return this.network.get(this.network.endpoints.coupons, params)
  }
  delete = (id) => {
    return this.network.delete(`${this.network.endpoints.coupons}/${id}`)
  }
  show = (id) => {
    return this.network.get(`${this.network.endpoints.coupons}/${id}`)
  }
  prepareCoupon = (data) => {
    let p = data
    p.formatted_created_at = moment(p.created_at).format('DD/MM/YYYY')
    p.formatted_created_by = p.type_use == 'psy' ? 'Psicólogo' : 'Admin'
    if (p.redeem_by) {
      p.formatted_expiration_date = moment(p.redeem_by).format('DD/MM/YYYY')
      p.formatted_status = moment(p.redeem_by).isBefore(moment(), 'D') ? 'expired' : 'active'
    } else {
      p.formatted_expiration_date = 'N/A'
      p.formatted_status = 'active'
    }
    p.formatted_status_obj = this.coupomStatusObj[p.formatted_status]
    return p
  }
}
