<template>
  <div id="appointment_feedback">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h2 class="fw-bold mb-4">
              Avaliação do paciente
            </h2>
            <div class="list-group list-group-flush">
              <div class="list-group-item"  v-if="item.user_rating">
                <div class="row align-items-center">
                  <div class="col">
                    <h4 class="mb-1 fw-bold">
                      Avaliação sobre o Psi
                    </h4>
                    <small class="text-body-secondary">
                      O que o paciente achou sobre o Psi
                    </small>
                  </div>
                  <div class="col-auto">
                    <div class="" v-if="item.user_rating">
                      <RatingStars :maxRating="5" :rating="item.user_rating?.psi_rating || 0" />
                    </div>
                  </div>
                </div>
                <TextAreaInput :rows="4" :readOnly="true" label="" :value="item.user_rating?.testimonial || ''" class="mb-0"/>
              </div>
              <div class="list-group-item"  v-if="item.user_rating">
                <div class="row align-items-center">
                  <div class="col">
                    <h4 class="mb-1 fw-bold">
                      Avaliação sobre a qualidade da ligação
                    </h4>
                    <small class="text-body-secondary">
                      O que o paciente achou sobre a qualidade
                    </small>
                  </div>
                  <div class="col-auto">
                    <div class="" v-if="item.user_rating">
                      <RatingStars :maxRating="5" :rating="item.user_rating?.rating || 0" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="list-group-item"  v-if="item.user_rating">
                <div class="row align-items-center">
                  <div class="col">
                    <h4 class="mb-1 fw-bold">
                     Teve algum problema?
                    </h4>
                    <small class="text-body-secondary">
                     Relato se o paciente teve algum problema na ligação.
                    </small>
                  </div>
                </div>
                <TextAreaInput :rows="4" :readOnly="true" label="" :value="item.user_rating?.description || ''" class="mb-0"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h2 class="fw-bold mb-4">
              Avaliação do psicologo
            </h2>
            <div class="list-group list-group-flush">
              <div class="list-group-item"  v-if="item.user_rating">
                <div class="row align-items-center">
                  <div class="col">
                    <h4 class="mb-1 fw-bold">
                      Avaliação sobre o Psi
                    </h4>
                    <small class="text-body-secondary">
                      O que o paciente achou sobre o Psi
                    </small>
                  </div>
                  <div class="col-auto">
                    <div class="" v-if="item.user_rating">
                      <RatingStars :maxRating="5" :rating="item.psi_rating?.rating  || 0" />
                    </div>
                  </div>
                </div>
                <TextAreaInput :rows="4" :readOnly="true" label="" :value="item.psi_rating?.description || ''" class="mb-0"/>
              </div>
            </div>
          </div>
        </div>
      </div>







      <!-- <div class="col-12 col-xl-12" v-if="item.psi_rating">
        <RatingStars :maxRating="5" :rating="item.psi_rating?.rating || 0" />
        <TextAreaInput :rows="4" :readOnly="true" label="Descrição" :value="item.psi_rating?.description || ''" />
      </div> -->
      <!-- <div v-else>
        <h4>Não há avaliação por parte do psicólogo</h4>
      </div> -->




    </div>
  </div>
</template>
<script>
export default {
  name: 'FeedbackComponent',
  props: {
    item: Object,
    default: {}
  }
}
</script>
